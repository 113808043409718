import $ from 'jquery'
require('../../lib/jquery.autoresize')

$(document).on('turbolinks:load', function(e) {
  $('#new_todo_item textarea').autoResize({'extraSpace': 0})

  $('body').on('submit', '#new_todo_item', function (e) {
    let form = $(this)

    // POST the form with jQuery:
    $.ajax({
      type:    'POST',
      url:      form.attr('action'),
      data:     form.serialize(),
      dataType: 'script'
    })

    // Stop the form from submitting:
    e.preventDefault()

    // Disable the inputs:
    form.find('input, textarea, select')
        .attr('disabled', 'disabled')
  })

  $('body').on('change', '.todo-items li input', function (e) {
    let input = $(this)
    let form  = input.parents('form').slice(0,1)

    // PUT the form with jQuery:
    $.ajax({
      type:    'PUT',
      url:      form.attr('action'),
      data:     form.serialize(),
      dataType: 'script'
    })

    // Disable the input:
    form.find('input').attr('disabled', 'disabled')
  })

  // $('.todo-form input[type=submit]').hide()
})
