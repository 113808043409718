import $ from 'jquery'
import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['holidays', 'laterDays', 'expandLaterLink']

  connect() {
    this.holidaysTargets.forEach(function(element) {
      var expandLabel = element.dataset.labelExpand
      var laterDays   = $(element.querySelector('.holidays-later-days')).hide()

      var expandHTML = `<p class='section-more holidays-expand-later'><a class='holidays-expand-later-link' data-action='forms--holidays#toggle' data-${this.identifier}-target='expandLaterLink'>${expandLabel}</a></p>`

      laterDays.after(expandHTML)
    })
  }

  toggle(event) {
    var expandLabel   = this.holidaysTarget.dataset.labelExpand
    var collapseLabel = this.holidaysTarget.dataset.labelCollapse

    if (this.holidaysTarget.classList.contains('holidays-expanded')) {
      this.holidaysTarget.classList.remove('holidays-expanded')
      this.expandLaterLinkTarget.textContent = expandLabel
      $(this.laterDaysTarget).slideUp(300)
    } else {
      this.holidaysTarget.classList.add('holidays-expanded')
      this.expandLaterLinkTarget.textContent = collapseLabel
      $(this.laterDaysTarget).slideDown(300)
    }
  }
}
