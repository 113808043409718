import L from 'leaflet'
import ClusterMarker from 'lib/maps/cluster_marker'
import CustomEvent from 'lib/polyfills/custom_event'

export default class extends ClusterMarker {

  constructor (element, options = {}) {
    let origin = (element.dataset.markerOrigin || '').split(',').map((c) => parseFloat(c))

    options.icon = new L.divIcon({
      className:  'marker venue inactive',
      iconSize:   [13, 13],
      iconAnchor: [ 7,  7],
      html:       `
        <div class="marker-wrapper" data-results-map-target="marker" data-result-id="${element.dataset.markerId}" data-action="mouseover->results-map#mouseOverMarker mouseout->results-map#mouseOutMarker">
          <div class="marker-content">
            <span>${element.dataset.markerLabel}</span>
          </div>
        </div>`
    })

    super(origin, options)

    this.result   = element
    this.resultId = element.dataset.markerId

    // POPUP:
    this.popup = L.popup({ closeButton: true, offset: [0, 0] })
    this.popup.setContent(
      `
        <strong><a href="${this.result.dataset.markerUrl}">${this.result.dataset.markerLabel}</a></strong>
        <span class="address">${this.result.dataset.markerAddress}</span>
      `
    )

    this.popup.on('add',    this.onOpenPopup.bind(this))
    this.popup.on('remove', this.onClosePopup.bind(this))

    this.leaflet.bindPopup(this.popup)
  }

  openPopup () {
    this.leaflet.openPopup()
  }

  onOpenPopup () {
    this.updateIcon()
    this.triggerEvent('popupopen')
  }

  onClosePopup () {
    this.updateIcon()
    this.triggerEvent('popupclose')
  }

  updateIcon() {
    this.icon.removeClass('popupped')
    super.updateIcon()

    if (this.hasOpenPopup) {
      this.icon.addClass('popupped')
    }
  }

  triggerEvent (name) {
    let event = new CustomEvent(name, {
      detail:     { marker: this },
      bubbles:    true,
      cancelable: false
    })

    document.dispatchEvent(event)
  }
}
