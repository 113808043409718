import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [ 'group', 'toggle' ]

  toggle (event) {
    event.preventDefault()

    if (this.hasGroupTarget) {
      this.groupTarget.classList.toggle('is-toggled')
    }
  }
}
