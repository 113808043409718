import * as d3 from 'd3'
import I18n from 'i18n-js'
import _ from 'underscore'
import ApplicationController from '../application_controller'

const MARGIN_LABELS = 6
const MARGIN_TOP    = 6 * 2
const MARGIN_BOTTOM = 6 * 4

export default class extends ApplicationController {

  static targets = ['data', 'output']

  get output () {
    return d3.select(this.outputTarget)
  }

  get inputData () {
    let rows = d3.select(this.dataTarget).selectAll('tr').nodes()
    return rows.map(
      (row) => {
        row = d3.select(row)

        let date  = row.select('th').text()
        let value = row.select('td').text()

        return {
          snapshot: new Date(1000 * parseInt(date)),
          value:    parseInt(value)
        }
      }
    )
  }

  get dates() {
    return this.inputData.map(datum => datum.date)
  }

  get values() {
    return this.inputData.map(datum => datum.value)
  }

  get svg() {
    let existing = this.output.select('svg')
    if (existing.size()) {
      return existing
    } else {
      return this.output.append('svg')
    }
  }

  get group() {
    let existing = this.svg.select('g')
    if (existing.size()) {
      return existing
    } else {
      return this.svg.append('g')
    }
  }

  get rectangle() {
    return this.svg.node().getBoundingClientRect()
  }

  get width() {
    return this.rectangle.width
  }

  get height() {
    return this.rectangle.height
  }

  connect() {
    this.resize = this.resize.bind(this)
    window.addEventListener('resize', this.resize)

    _.defer(() => this.draw())
  }

  disconnect() {
    window.removeEventListener('resize', this.resize)
    _.defer(() => this.undraw())
  }

  resize(event) {
    this.undraw()
    this.draw()
  }

  prepareScales() {
    let minValue = Math.min(...this.values)
    let maxValue = Math.max(...this.values)

    this.yScale = d3.scaleLinear()
    this.yScale.domain([Math.min(0, minValue), Math.max(100, maxValue)])

    this.xScale = d3.scaleTime()
    this.xScale.domain(d3.extent(this.inputData, (datum) => datum.snapshot))
  }

  undraw() {
    if (this.group.size()) {
      this.group.selectAll('.trend-line, .x-axis, .y-axis').remove()
    }
  }

  draw() {
    this.prepareScales()

    this.yScale.rangeRound([this.height - MARGIN_TOP - MARGIN_BOTTOM, 0])

    let line =
      d3.line()
        .y((datum) => this.yScale(datum.value))
        .x((datum) => this.xScale(datum.snapshot))

    let yAxis =
      d3.axisLeft(this.yScale)
        .tickValues([0, 20, 40, 60, 80, 100])
        .tickFormat((tick) => tick / 10)

    let yAxisGroup =
      this.group
          .append('g')
          .attr('class', 'y-axis').call(yAxis)

    yAxisGroup.selectAll('text')
              .attr('x', 0)
    yAxisGroup.selectAll('.tick text')
              .attr('transform', `translate(-${MARGIN_LABELS}, 0)`)

    let labels     = yAxisGroup.selectAll('text').nodes()
    let labelWidth = Math.ceil(d3.max(labels, (l) => l.getComputedTextLength()))
    let pathLeft   = labelWidth + MARGIN_LABELS

    this.xScale.rangeRound([pathLeft, this.width])

    yAxisGroup.attr('transform', `translate(${pathLeft}, ${MARGIN_TOP})`)
    yAxisGroup.selectAll('line').attr('x2', this.width - pathLeft)

    let xAxisGroup = this.group.append('g').attr('class', 'x-axis')

    let firstLabel =
      xAxisGroup.append('text')
                .attr('class', 'then')
                .attr('text-anchor', 'begin')
                .attr('x', 0)
                .text(this.firstLabel())

    let lastLabel =
      xAxisGroup.append('text')
                .attr('class', 'now')
                .attr('text-anchor', 'end')
                .attr('x', this.width - pathLeft)
                .text(this.lastLabel())

    let labelHeight = d3.max([firstLabel, lastLabel], (l) => l.node().getBBox().height)
    let labelTop    = this.height - (MARGIN_BOTTOM - labelHeight) / 2

    xAxisGroup.attr('transform', `translate(${pathLeft}, ${labelTop})`)

    this.group
        .append('path')
        .datum(this.inputData)
        .attr('class', 'trend-line')
        .attr('transform', `translate(0, ${MARGIN_TOP})`)
        .attr('d', line)
  }

  firstLabel() {
    return I18n.l('date.formats.friendly_date_with_year', this.inputData[0].snapshot)
  }

  lastLabel() {
    return I18n.t('venues.review_stats.now')
  }
}
