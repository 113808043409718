import $ from 'jquery'
import _ from 'underscore'
import ApplicationController from '../application_controller'

require('../../lib/jquery.swapable')

export default class extends ApplicationController {

  connect () {
    this.addCursors     = this.addCursors.bind(this)
    this.resetPositions = this.resetPositions.bind(this)

    $(document).on('add-form-fields', this.resetPositions)
    $(document).on('add-form-fields', this.addCursors)

    _.defer(this.resetPositions)
    _.defer(this.addCursors)
  }

  // ADD CURSORS:

  addCursors () {
    this.addCategoryPositionCursors()
    this.addDishPositionCursors()
    this.addOptionGroupPositionCursors()
    this.addOptionPositionCursors()
  }

  addCategoryPositionCursors() {
    $('.menu-form-category-position').each(function() {
      let wrapper = $(this)
      if (!wrapper.find('.menu-form-category-position-actions').length) {
        wrapper.append(`\
          <ul class="menu-form-category-position-actions">
            <li>
              <a data-action='click->menu-editor--editor#moveCategory'
                 class="menu-form-category-position-up">${
                wrapper.data('move-up-label')
              }</a>
            </li>
            <li>
              <a data-action='click->menu-editor--editor#moveCategory'
                 class="menu-form-category-position-down">${
                wrapper.data('move-down-label')
              }</a>
            </li>
          </ul>\
        `)
      }
    })
  }

  addDishPositionCursors() {
    $('.menu-form-dish-position').each(function() {
      let wrapper = $(this)
      if (!wrapper.find('.menu-form-dish-position-actions').length) {
        wrapper.append(`\
          <ul class="menu-form-dish-position-actions">
            <li>
              <a data-action='click->menu-editor--editor#moveDish'
                 class="menu-form-dish-position-up">${
                wrapper.data('move-up-label')
              }</a>
            </li>
            <li>
              <a data-action='click->menu-editor--editor#moveDish'
                 class="menu-form-dish-position-down">${
                wrapper.data('move-down-label')
              }</a>
            </li>
          </ul>\
        `)
      }
    })
  }

  addOptionGroupPositionCursors() {
    $('.menu-form-option-group-position').each(function() {
      let wrapper = $(this)
      if (!wrapper.find('.menu-form-option-group-position-actions').length) {
        wrapper.append(`\
          <ul class="menu-form-option-group-position-actions">
            <li>
              <a data-action='click->menu-editor--editor#moveOptionGroup'
                 class="menu-form-option-group-position-up">${
                wrapper.data('move-up-label')
              }</a>
            </li>
            <li>
              <a data-action='click->menu-editor--editor#moveOptionGroup'
                 class="menu-form-option-group-position-down">${
                wrapper.data('move-down-label')
              }</a>
            </li>
          </ul>\
        `)
      }
    })
  }

  addOptionPositionCursors() {
    $('.menu-form-option-position').each(function() {
      let wrapper = $(this)
      if (!wrapper.find('.menu-form-option-position-actions').length) {
        wrapper.append(`\
          <ul class="menu-form-option-position-actions">
            <li>
              <a data-action='click->menu-editor--editor#moveOption'
                 class="menu-form-option-position-up">${
                wrapper.data('move-up-label')
              }</a>
            </li>
            <li>
              <a data-action='click->menu-editor--editor#moveOption'
                 class="menu-form-option-position-down">${
                wrapper.data('move-down-label')
              }</a>
            </li>
          </ul>\
        `)
      }
    })
  }

  // SET POSITIONS:

  resetPositions () {
    this.setCategoryPositions()
    this.setDishPositions()
    this.setOptionGroupPositions()
    this.setOptionPositions()
  }

  setElementPositions (wrapperSelector, elementSelector, inputSelector, firstClass, lastClass) {
    $(wrapperSelector).each(function(index) {
      let wrapper  = $(this)
      let elements = wrapper.find(elementSelector)

      elements.each(function(index) {
        let element = $(this)
        let input   = element.find(inputSelector)

        element.removeClass(firstClass)
        element.removeClass(lastClass)

        input.val(index + 1)
      })

      elements.first().addClass(firstClass)
      elements.last().addClass(lastClass)
    })
  }

  setCategoryPositions () {
    this.setElementPositions(
      '.menu-form',
      '.menu-form-category:visible',
      '.menu-form-category-position input',
      'first-category', 'last-category'
    )
  }

  setDishPositions () {
    this.setElementPositions(
      '.menu-form-category',
      '.menu-form-dish:visible',
      '.menu-form-dish-position input',
      'first-dish', 'last-dish'
    )
  }

  setOptionGroupPositions () {
    this.setElementPositions(
      '.menu-form-dish',
      '.menu-form-option-group:visible',
      '.menu-form-option-group-position input',
      'first-option-group', 'last-option-group'
    )
  }

  setOptionPositions () {
    // Set positions of options outside of option groups:
    this.setElementPositions(
      '.menu-form-dish',
      '.menu-form-option:visible',
      '.menu-form-option-position input',
      'first-option', 'last-option'
    )

    // Set positions of options inside option groups:
    this.setElementPositions(
      '.menu-form-option-group',
      '.menu-form-option:visible',
      '.menu-form-option-position input',
      'first-option', 'last-option'
    )
  }

  // MOVE POSITIONS:

  moveElement (event, elementSelector, upSelector, siblingSelector, callback) {
    event.preventDefault()

    let sibling = null
    let cursor  = $(event.currentTarget)
    let element = cursor.closest(elementSelector)

    if (cursor.is(upSelector)) {
      sibling = element.prevAll(siblingSelector).first()
    } else {
      sibling = element.nextAll(siblingSelector).first()
    }

    if (sibling != null ? sibling.length : undefined) {
      let maxHeight = Math.max(element.height(), sibling.height())
      element.swap(sibling, {
        duration: Math.max(750, maxHeight * 0.75),
        complete: callback.bind(this)
      })
    }
  }

  moveDish (event) {
    this.moveElement(
      event,
      '.menu-form-dish',
      '.menu-form-dish-position-up',
      '.menu-form-dish:visible',
      this.setDishPositions
    )
  }

  moveCategory (event){
    this.moveElement(
      event,
      '.menu-form-category',
      '.menu-form-category-position-up',
      '.menu-form-category:visible',
      this.setCategoryPositions
    )
  }

  moveOptionGroup (event) {
    this.moveElement(
      event,
      '.menu-form-option-group',
      '.menu-form-option-group-position-up',
      '.menu-form-option-group:visible',
      this.setOptionGroupPositions
    )
  }

  moveOption (event) {
    this.moveElement(
      event,
      '.menu-form-option',
      '.menu-form-option-position-up',
      '.menu-form-option:visible',
      this.setOptionPositions
    )
  }
}
