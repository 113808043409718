import _ from 'underscore'
import { currentUser } from '../models/current_user'
import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect() {
    currentUser.fetch().done(() => {
      this.user = currentUser.user
      this.addAvatar()
    }).fail(() => {
      this.removeAvatar()
    })
  }

  addAvatar () {
    this.element.innerHTML = `<img src="${this.user.avatar}" />`
  }

  removeAvatar () {
    this.element.innerHTML = ''
  }

}
