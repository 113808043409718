import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [ 'list', 'more' ]

  connect () {
    if (this.listTarget.childElementCount > 5) {
      this.element.classList.add('children-hidden')
    }
  }

  show (event) {
    event.preventDefault()
    this.element.classList.toggle('children-hidden')
  }
}
