import { currentUser } from 'models/current_user'
import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  get input () {
    if (this.element.nodeName.toLowerCase() == 'input') {
      return this.element
    } else {
      return this.element.getElementsByTagName('input')[0]
    }
  }

  connect() {
    currentUser.fetch().done(() => {
      this.user = currentUser.user
      this.autofill()
    }).fail(() => {
      this.unfill()
      this.user = null
    })
  }

  autofill () {
    this.input.value = this.user.email
  }

  unfill () {
    if (this.user && this.input.value == this.user.email) {
      this.input.value = ''
    }
  }
}
