import L from 'leaflet'
import _ from 'underscore'

export default class {
  get defaultOptions () {
    return {
      originMarker:       false,
      zoomControl:        true,
      dragging:           true,
      doubleClickZoom:    true,
      scrollWheelZoom:    true,
      inertiaMaxSpeed:    1000,
      attributionControl: false,
      tileLayer: {"url":"https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}.png?apikey=3daf7a4ca45d46f1a440aa17ea610c6f","subdomains":[]}
    }
  }

  get maxZoomlevel() { return this.options.zoom + 1  }
  get zoomlevel ()   { return this.leaflet.getZoom() }
  set zoomlevel (v)  { this.leaflet.setZoom(v)       }

  get hasOpenPopup () {
    return this.markers.some((marker) => marker.hasOpenPopup)
  }

  get markers () { return this._markers || [] }
  set markers (markers) {
    this.clearMarkers()
    this._markers = markers
    this.addMarkers()
  }

  get activeMarkers () { return this.markers.filter((marker) => marker.active) }
  set activeMarkers (markers) {
    if (_.isEqual(markers.sort(), this.activeMarkers)) return

    // Deactivate markers that are not visible anymore:
    _.difference(this.activeMarkers, markers).forEach((marker) => this.deactivateMarker(marker))

    // Activate markers that are now visible:
    _.difference(markers, this.activeMarkers).forEach((marker) => this.activateMarker(marker))

    this.processView()

    this.panToActiveMarkers()
  }

  // Setup:

  constructor (container, options = {}) {
    this.options = _.defaults(options, this.defaultOptions)

    // Set up map:
    this.leaflet = L.map(container, this.options)

    // Add tile layer:
    let tileLayer = this.options.tileLayer
    if (tileLayer) {
      this.tileLayer = L.tileLayer(tileLayer.url, { subdomains: tileLayer.subdomains })
      this.addTileLayer()
    }

    // Set up events:
    this.on('moveend', this.makeLabelsReadable.bind(this))
  }

  addTileLayer () {
    if (this.tileLayer) {
      this.tileLayer.addTo(this.leaflet)
    }
  }

  removeTileLayer () {
    this.leaflet.removeLayer(this.tileLayer)
  }

  // Unsetup:

  destroy () {
    this.leaflet.remove()
  }

  // Events:

  on (event, callback) {
    this.leaflet.on(event, callback)
  }

  processView () {

  }

  // Markers:

  addMarkers () {
    this.markers.forEach((marker) => marker.addTo(this))
    this.processView()
  }

  clearMarkers () {
    if (this.markers) {
      this.markers.forEach((marker) => marker.removeFrom(this))
    }
    this.processView()
  }

  activateMarker (marker) {
    marker.activate()
  }

  deactivateMarker (marker) {
    marker.deactivate()
  }

  // Actions:

  panToActiveMarkers () {
    if (this.activeMarkers.length > 0) {
      let points = this.activeMarkers.map((marker) => marker.latLng)

      let bounds = L.latLngBounds(points)
      if (this.options.center) {
        bounds = bounds.extend(this.options.center)
      }

      this.leaflet.fitBounds(bounds, {
        paddingTopLeft:     [24, 154],
        paddingBottomRight: [24, 24],
        maxZoom:            this.maxZoomlevel
      })
    } else {
      // @flyToOrigin true
    }
  }

  makeLabelsReadable () {
    /*
     * This uses a greedy algorithm to position the markers. When positioning the
     * label, all other markers are taken into account as well as the labels of
     * markers for which the label has been positioned.
     */
    this.activeMarkers.forEach((marker, index) => {
      let previousMarkers = _.take(this.activeMarkers, index)
      let nextMarkers     = _.drop(this.activeMarkers, index + 1)

      marker.setLabelStyle()
      marker.repositionLabel(previousMarkers, nextMarkers)
    })
  }
}
