
import jQuery from 'jquery'

(function($) { 

var swapElements = function(master, slave, options) {
  const defaults = {
    duration: 750,
    easing:   'swing'
  };
  
  options = $.extend({}, defaults, options);
  
  master = $(master);
  slave  = $(slave);
  
  if (!(master != null ? master.length : undefined) || !(slave != null ? slave.length : undefined)) { return; }
  
  const origin      = master.offset();
  const destination = slave.offset();
  
  // If the master is below the slave, switch them around for the transition
  // because this seems to fix stuff!
  if (destination.top < origin.top) { return swapElements(slave, master, options); }
  
  const masterClone = master.clone();
  const slaveClone  = slave.clone();
  
  master.addClass('master-swapper').css({visibility: 'hidden'});
  masterClone.insertBefore(master).css({
    position:  'absolute',
    width:     master.width(),
    height:    master.height(),
    'z-index': 9999
  }).offset(origin).addClass('master-swapper-clone');
  
  slave.addClass('slave-swapper').css({visibility: 'hidden'});
  slaveClone.insertBefore(slave).css({
    position:  'absolute',
    width:     slave.width(),
    height:    slave.height(),
    'z-index': 9998
  }).offset(destination).addClass('slave-swapper-clone');
  
  const verticalDistanceTotal    = (destination.top  + slave.height()) - origin.top;
  const horizontalDistanceTotal  = (destination.left + slave.width())  - origin.left;
  let verticalDistanceSlave    = 0;
  let verticalDistanceMaster   = 0;
  let horizontalDistanceSlave  = 0;
  let horizontalDistanceMaster = 0;
  
  if (origin.top !== destination.top) {
    verticalDistanceSlave  = verticalDistanceTotal - slave.height();
    verticalDistanceMaster = verticalDistanceTotal - master.height();
  }
  
  if (origin.left !== destination.left) {
    horizontalDistanceSlave  = horizontalDistanceTotal - master.width();
    horizontalDistanceMaster = horizontalDistanceTotal - slave.width();
  }
  
  const slaveAnimation  = slaveClone.animate({ top: `-=${verticalDistanceSlave}px`,  left: `-=${horizontalDistanceSlave}px`  }, {duration: options.duration, easing: options.easing});
  const masterAnimation = masterClone.animate({ top: `+=${verticalDistanceMaster}px`, left: `+=${horizontalDistanceMaster}px` }, {duration: options.duration, easing: options.easing});
  
  return $.when(slaveAnimation, masterAnimation).then(() => {
    master.insertBefore(slaveClone).css({visibility: 'visible'});
    masterClone.hide();
    
    slave.insertBefore(masterClone).css({visibility: 'visible'});
    slaveClone.hide();
    
    master.removeClass('master-swapper');
    slave.removeClass('slave-swapper');
    
    masterClone.remove();
    slaveClone.remove();
    
    return __guardMethod__(options, 'complete', o => o.complete());
  });
};

$.fn.swap = function(slave, options) {
  if (options == null) { options = {}; }
  return swapElements($(this), $(slave), options);
};

function __guardMethod__(obj, methodName, transform) {
  if (typeof obj !== 'undefined' && obj !== null && typeof obj[methodName] === 'function') {
    return transform(obj, methodName);
  } else {
    return undefined;
  }
}
})(jQuery)