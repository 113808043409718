import $ from 'jquery'
import _ from 'underscore'
import ApplicationController from '../application_controller'
import Bloodhound from 'corejs-typeahead'

require('corejs-typeahead/dist/typeahead.bundle.js')
require('../../lib/polyfills/starts_with')

export default class extends ApplicationController {

  static targets = ['searchInput']

  static values = {
    autocompleteUrl: { type: String, default: '/autocomplete/what' },
    locale:          { type: String, default: 'nl'                 }
  }

  disconnect () {
    if (this.input) {
      this.input.typeahead('destroy')
    }
  }

  connect () {
    _.defer(() => {
      if (!this.hasSearchInputTarget) return

      const input  = this.input  = $(this.searchInputTarget)
      const engine = this.engine = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: `${this.autocompleteUrlValue}?query=&locale=${this.localeValue}`,
        remote: {
          wildcard: '%QUERY',
          url:      `${this.autocompleteUrlValue}?query=%QUERY&locale=${this.localeValue}`,
          filter: this.tagFromResponse.bind(this)
        }
      })

      engine.initialize()

      input.typeahead({
        hint:      true,
        highlight: true,
        minLength: 0
      }, {
        source:      engine.ttAdapter(),
        displayKey: 'title',
        limit: 7,
        templates: {
          header: this.tagHeader,
          suggestion: this.tagSuggestion
        }
      })
    })
  }

  tagHeader(){
    return `<div class='tag-suggestion-header'>
              <span class='tag-suggestion-header header-text'>${I18n.t('tag_autocomplete.example')}</span>
            </div>
            `
  }

  tagSuggestion (tag) {
    if(tag.title == null && tag.name){
      tag.title = tag.name
    }

    return `<div class='tag-suggestion' tt-suggestion tt-selectable'>
              ${tag.title}
            </div>`
  }

  tagFromResponse(response) {
    var sortedResponse = response.results

    return sortedResponse
  }
}
