import _ from 'underscore'
import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [ 'navigation', 'section' ]

  connect () {
    this.element.classList.remove('hidden')
    this.hideSegmentedSections()

    if (location.hash) {
      this.element.querySelector(location.hash).classList.remove('hidden')
      this.navigationTarget.querySelector('a[href="'+location.hash+'"]').closest('li').classList.add('selected')
    }
    else {
      this.element.querySelector('.segmented-section:first-of-type').classList.remove('hidden')
      this.navigationTarget.querySelector('li:first-child').classList.add('selected')
    }
  }

  showSegmentedSection () {
    var target   = event.currentTarget.getAttribute('href')
    var listItem = this.navigationTarget.querySelectorAll('li')

    event.preventDefault()

    location.hash = target

    this.hideSegmentedSections()
    this.element.querySelector(target).classList.remove('hidden')

    for (var i = 0; i < listItem.length; i++) {
      listItem[i].classList.remove('selected')
    }

    this.navigationTarget.querySelector('a[href="'+location.hash+'"]').closest('li').classList.add('selected')
  }

  hideSegmentedSections () {
    var segmentedSection = this.element.querySelectorAll('.segmented-section')

    for (var i = 0; i < segmentedSection.length; i++) {
      segmentedSection[i].classList.add('hidden')
    }
  }
}
