import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [ 'link' ]

  openGallery(event) {
    let venueCommunityGallery = document.querySelector(this.linkTarget.getAttribute("href"))

    event.preventDefault()

    venueCommunityGallery.querySelectorAll('a[data-modal-variant="lightbox"]')[0].click()
  }
}
