import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  connect () {
    if (document.querySelectorAll('.expandable-form .error').length) {
      this.expand()
    }
  }

  expand () {
    this.element.classList.add('expanded')
  }
}
