import _ from 'underscore'
import { currentUser } from '../../models/current_user'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [ 'method', 'button' ]

  set following (value) {
    this.data.set('following', value)
    this.updateButtonLabel()
  }

  get following () {
    return this.data.get('following') == 'true'
  }

  get followedVenueIds () {
    if (this.currentUser && this.currentUser.followed_venues) {
      return _.map(this.currentUser.followed_venues, id => parseInt(id))
    } else {
      return []
    }
  }

  connect () {
    this.venueId = parseInt(this.data.get('venue-id'))

    let callback = this.updateFollowing.bind(this)

    currentUser.subscribe(this, callback)
    currentUser.fetch().done(callback).fail(callback)
  }

  updateFollowing() {
    this.currentUser = currentUser.user
    this.following   = _.contains(this.followedVenueIds, this.venueId)

    this.updateButtonLabel()
  }

  updateButtonLabel() {
    if (this.following) {
      this.buttonTarget.innerText = this.data.get('unfollow-label')
      this.buttonTarget.classList.add('active')
      this.methodTarget.value = 'delete'
    } else {
      this.buttonTarget.innerText = this.data.get('follow-label')
      this.buttonTarget.classList.remove('active')
      this.methodTarget.value = 'post'
    }
  }
}
