import _ from 'underscore'
import ApplicationController from '../application_controller'

const google = window.google

export default class extends ApplicationController {

  static targets = ['mapCanvas', 'latInput', 'lngInput']

  get supportsGoogleMaps () {
    return !!(
      google && google.maps && google.maps.Map
    )
  }

  get zoomlevel () { return parseInt(this.data.get('zoomlevel') || 15) }

  get latitude () { return this.hasLatInputTarget && parseFloat(this.latInputTarget.value) }
  set latitude (value) {
    if (this.hasLatInputTarget) this.latInputTarget.value = value
  }

  get longitude () { return this.hasLngInputTarget && parseFloat(this.lngInputTarget.value) }
  set longitude (value) {
    if (this.hasLngInputTarget) this.lngInputTarget.value = value
  }

  connect () {
    _.defer(() => {
      if (this.hasMapCanvasTarget && this.supportsGoogleMaps) {
        let center   = new google.maps.LatLng(this.latitude, this.longitude)
        let options  = {
          zoom:      this.zoomlevel,
          center:    center,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        }

        this.map = new google.maps.Map(this.mapCanvasTarget, options)
        this.marker = new google.maps.Marker({ position: center, map: this.map })

        google.maps.event.addListener(this.map, 'click', this.repositionMarker.bind(this))
      }
    })
  }

  repositionMarker (event) {
    let position = event.latLng
    if (position) {
      this.latitude  = position.lat()
      this.longitude = position.lng()
      this.updateMarkerPosition()
    }
  }

  updateMarkerPosition () {
    if (this.map && this.marker) {
      let center = new google.maps.LatLng(this.latitude, this.longitude)
      this.marker.setPosition(center)
      this.map.setCenter(center)
    }
  }
}
