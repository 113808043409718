import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const E = window.E = window.E || { }

// Stimulus:
E.application = Application.start()
E.context = require.context('controllers', true, /\.js$/)
E.application.load(definitionsFromContext(E.context))

Object.defineProperty(E, 'modal', {
  get: function() {
    return E.application.getControllerForElementAndIdentifier(document.body, 'modal')
  }
})

Object.defineProperty(E, 'notifications', {
  get: function() {
    return E.application.getControllerForElementAndIdentifier(document.body, 'notifications')
  }
})
