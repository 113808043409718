import ApplicationController from './application_controller'

export default class extends ApplicationController {
  expand (event) {
    var mainNavigation = document.querySelector(".main-navigation")

    event.preventDefault()

    mainNavigation.classList.add('is-expanded')
  }
}
