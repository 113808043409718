import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [ 'tabular' ]

  toggle () {
    this.tabularTarget.classList.toggle('only-changes')
  }
}
