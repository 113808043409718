import _ from 'underscore'
import ApplicationController from '../application_controller'
import { currentUser } from '../../models/current_user'

export default class extends ApplicationController {

  static targets = ['orderCounter', 'reservationCounter']

  get counters () {
    let counterKey = this.data.get('id')
    if (counterKey && this.user && this.user.counters) {
      return this.user.counters[counterKey]
    }
  }

  connect () {
    this.addCounters   = this.addCounters.bind(this)
    this.removeCounter = this.removeCounters.bind(this)

    this.loadUser()
  }

  disconnect () {
    this.removeCounters()
  }

  loadUser () {
    this.user = null
    currentUser.fetch().done(() => {
      this.user = currentUser.user
      this.addCounters()
    }).fail(() => {
      this.removeCounters()
    })
  }

  addCounters () {
    let counters = this.counters

    if (!counters) { return }

    if (this.hasOrderCounterTarget) {
      if (counters.unprocessed_menu_orders > 0) {
        this.orderCounterTarget.innerHTML = `<abbr class="new-notifications">${counters.unprocessed_menu_orders}</abbr>`
      } else if (counters.upcoming_menu_orders > 0) {
        this.orderCounterTarget.innerHTML = `<abbr class="existing-notifications">${counters.upcoming_menu_orders}</abbr>`
      } else {
        this.orderCounterTarget.innerHTML = ''
      }
    }

    if (this.hasReservationCounterTarget) {
      if (counters.unprocessed_reservations > 0) {
        this.reservationCounterTarget.innerHTML = `<abbr class="new-notifications">${counters.unprocessed_reservations}</abbr>`
      } else if (counters.upcoming_reservations > 0) {
        this.reservationCounterTarget.innerHTML = `<abbr class="existing-notifications">${counters.upcoming_reservations}</abbr>`
      } else {
        this.reservationCounterTarget.innerHTML = ''
      }
    }
  }

  removeCounters () {
    if (this.hasOrderCounterTarget)       { this.orderCounterTarget.innerHTML = '' }
    if (this.hasReservationCounterTarget) { this.reservationCounterTarget.innerHTML = '' }
  }
}
