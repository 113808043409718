import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = [ 'header', 'actions', 'user' ]

  connect () {
    this.resize()
  }

  resize () {
    var venueReservations   = '.venue-reservations'
    var venueNoReservations = 'no-reservations-widget'

    if ( document.body.contains(this.headerTarget) && document.body.contains(this.actionsTarget) ) {
      if ( !document.querySelectorAll(venueReservations).length ) {
        this.element.classList.add(venueNoReservations)
      }

      this.actionsTarget.style.cssText = 'margin-top: 0;'
      this.userTarget.style.cssText    = 'top: 0;'

      var sum = this.headerTarget.offsetTop - this.actionsTarget.offsetTop

      if ( this.element.classList.contains(venueNoReservations) ) {
        this.userTarget.style.cssText = 'top: -186px;'
      } else {
        this.actionsTarget.style.cssText = 'margin-top:' + sum + 'px;'
      }
    }
  }
}
