`import $ from 'jquery';`

getCookie = (name) ->
  nameEQ = name + "="
  ca = document.cookie.split(";")
  i = 0
  
  while i < ca.length
    c = ca[i]
    c = c.substring(1, c.length)  while c.charAt(0) is " "
    return c.substring(nameEQ.length, c.length) if c.indexOf(nameEQ) is 0
    i++
  
  null

$(document).on 'turbolinks:load', (event) ->
  $('input[data-cookie-value], select[data-cookie-value]').each ->
    input = $ this
    key   = input.data('cookie-value')
    
    value = getCookie(key) if key?.length
    input.val(value)       if value?.length