import _ from 'underscore'

import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [
    'titleInput', 'descriptionInput', 'priceInput',
    'visibleInput', 'invisibleTillInput',
    'invisibleTillWrapper',
    'addOptionGroupLink', 'addOptionLink'
  ]

  get category () {
    return this.findParentController('menu-editor--category')
  }

  get title () {
    return this.hasTitleInputTarget && this.titleInputTarget.value
  }
  set title (newTitle) {
    if (this.hasTitleInputTarget) {
      this.titleInputTarget.value = newTitle
    }
  }

  get description () {
    return this.hasDescriptionInputTarget && this.descriptionInputTarget.value
  }
  set description (newDescription) {
    if (this.hasDescriptionInputTarget) {
      this.descriptionInputTarget.value = newDescription
    }
  }

  get price () {
    return this.hasPriceInputTarget && this.priceInputTarget.value
  }
  set price (newPrice) {
    if (this.hasPriceInputTarget) {
      this.priceInputTarget.value = newPrice
    }
  }

  get optionGroups () {
    return this.findChildControllers(
      'menu-editor--option-group'
    )
  }

  get options () {
    return this.findChildControllers(
      'menu-editor--option'
    ).filter((option) => !option.optionGroup)
  }

  get visible () {
    return this.visibleRadio.checked
  }

  set visible (v) {
    if (v) {
      this.invisibleRadio.checked = false
      this.visibleRadio.checked   = true
    } else {
      this.visibleRadio.checked   = false
      this.invisibleRadio.checked = true
    }

    this.updateVisibility()
  }

  get invisibleTill () {
    return this.invisibleTillInputTarget.value
  }

  set invisibleTill (v) {
    this.invisibleTillInputTarget.value = v
  }

  get visibleRadio () {
    return this.visibleInputTargets.find((radio) => {
      return radio.value == 'true'
    })
  }

  get invisibleRadio () {
    return this.visibleInputTargets.find((radio) => {
      return radio.value == 'false'
    })
  }

  get asJSON () {
    return {
      title:         this.title,
      description:   this.description,
      price:         this.price,
      optionGroups:  this.optionGroups.map((og) => og.asJSON),
      options:       this.options.map((o) => o.asJSON),
      visible:       this.visible,
      invisibleTill: this.invisibleTill
    }
  }

  initialize () {
    this.switchVisbilityLabelForTargets()
    this.updateVisibility()
  }

  focus () {
    if (this.hasTitleInputTarget) {
      this.titleInputTarget.focus()
    }
  }

  addOptionGroup (group) {
    if (group.destroyed) { return }

    if (this.hasAddOptionGroupLinkTarget) {
      let groupIndex = this.optionGroups.length
      this.addOptionGroupLinkTarget.onclick()

      _.defer(() => {
        let newGroup = this.optionGroups[groupIndex]

        newGroup.label          = group.label
        newGroup.required       = group.required
        newGroup.multipleChoice = group.multipleChoice
        newGroup.collapse()

        group.options.forEach(
          (option) => _.defer(() => newGroup.addOption(option))
        )
      })
    }
  }

  addOption (option) {
    if (option.destroyed) { return }

    if (this.hasAddOptionLinkTarget) {
      let optionIndex = this.options.length

      this.addOptionLinkTarget.onclick()

      _.defer(() => {
        let newOption = this.options[optionIndex]

        newOption.description = option.description
        newOption.price       = option.price
      })
    }
  }

  duplicate (event) {
    if (event) {
      event.preventDefault()
    }

    if (this.category) {
      this.category.addDish(this)
    }
  }

  changeTitle (event) {
    this.resetOptionGroupLabels()
  }

  resetOptionGroupLabels () {
    this.optionGroups.forEach((group) => group.resetLabel())
  }

  switchVisbilityLabelForTargets () {
    let visibleLabel   = this.visibleRadio.closest('label')
    let forVisible     = visibleLabel.getAttribute('for')
    let invisibleLabel = this.invisibleRadio.closest('label')
    let forInvisible   = invisibleLabel.getAttribute('for')

    visibleLabel.setAttribute('for', forInvisible)
    invisibleLabel.setAttribute('for', forVisible)
  }

  updateVisibility () {
    let visibleChoice   = this.visibleRadio.closest('li.choice')
    let invisibleChoice = this.invisibleRadio.closest('li.choice')

    if (this.visible) {
      visibleChoice.classList.add('active')
      invisibleChoice.classList.remove('active')

      this.invisibleTill = ''
      this.invisibleTillWrapperTarget.classList.add('is-hidden')
    } else {
      visibleChoice.classList.remove('active')
      invisibleChoice.classList.add('active')

      this.invisibleTillWrapperTarget.classList.remove('is-hidden')
    }
  }
}
