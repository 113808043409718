import _ from 'underscore'
import { currentUser } from '../models/current_user'
import ApplicationController from './application_controller'

/*
 * There are 3 classes that are added by the authorization controller:
 *
 * .authorized - User is authorized to see content
 * .not-authorized - User is NOT authorized to see content
 * .unauthenticated - No user is logged in
 *
 * Together with the various forms of authorization, you are able to show/hide
 * content with CSS by targetting those classes. Examples:
 *
 * .only-user.authorized - visible (user is logged in and allowed)
 * .only-user.not-authorized - not visible (user is logged in but not allowed)
 * .only-user.unauthenticated - not visible (user is not logged in)
 * .no-js .only-user - not visible (we don't know, in most cases content would be hidden)
 *
 * .except-user.authorized - visible (user is logged in and not excempt)
 * .except-user.not-authorized - not visible (user is logged in and except)
 * .except-user.unauthenticated - visible (user is nog logged in)
 * .no-js .except-user - visible (we don't know, in most cases content would be shown)
 *
 * .only-admin.authorized - visible (user is logged in and an admin)
 * .only-admin.not-authorized - not visible (user is logged in and not an admin)
 * .only-admin.unauthenticated - not visible (user is not logged in)
 * .no-js .only-admin - not visible (we don't know, in most cases content would be hidden)
 *
 */
export default class extends ApplicationController {
  get type () { return this.data.get('type') }

  get isUser       () { return (this.user && this.user.id) }
  get isAdmin      () { return (this.user && this.user.admin) }
  get isVenueOwner () { return this.isAdmin || (this.user && this.user.venue_ids.length > 0) }

  get userIds () {
    var data = this.data.get('user-id')  ||
               this.data.get('user-ids')

    if (!data) {
      return []
    } else {
      return _.map(`${data}`.split(','), id => parseInt(id))
    }
  }

  get referencesCurrentUser () {
    return this.isUser && (
      this.userIds.length === 0 ||
      _.contains(this.userIds, this.user.id)
    )
  }

  connect() {
    this.element.classList.add(this.type)

    currentUser.fetch().done(() => {
      this.user = currentUser.user
      this.authorize()
    }).fail(() => {
      this.unauthenticate()
    })
  }

  unauthenticate() {
    this.element.classList.remove('authorized')
    this.element.classList.remove('not-authorized')
    this.element.classList.add('unauthenticated')
  }

  authorize() {
    this.element.classList.remove('unauthenticated')
    this.element.classList.remove('authorized')
    this.element.classList.remove('not-authorized')

    switch (this.type) {
      case 'except-user':
        this.authorizeExceptUser()
        break

      case 'only-user':
        this.authorizeOnlyUser()
        break

      case 'only-user-or-admin':
        this.authorizeOnlyUserOrAdmin()
        break

      case 'only-admin':
        this.authorizeOnlyAdmin()
        break

      case 'only-venue-owner':
        this.authorizeOnlyVenueOwner()
        break
    }
  }

  authorizeExceptUser() {
    if (!this.referencesCurrentUser) {
      this.element.classList.add('authorized')
    } else {
      this.element.classList.add('not-authorized')
    }
  }

  authorizeOnlyUser() {
    if (this.referencesCurrentUser) {
      this.element.classList.add('authorized')
    } else {
      this.element.classList.add('not-authorized')
    }
  }

  authorizeOnlyUserOrAdmin() {
    if (this.referencesCurrentUser || this.isAdmin) {
      this.element.classList.add('authorized')
    } else {
      this.element.classList.add('not-authorized')
    }
  }

  authorizeOnlyAdmin() {
    if (this.isAdmin) {
      this.element.classList.add('authorized')
    } else {
      this.element.classList.add('not-authorized')
    }
  }

  authorizeOnlyVenueOwner() {
    if (this.isVenueOwner) {
      this.element.classList.add('authorized')
    } else {
      this.element.classList.add('not-authorized')
    }
  }
}
