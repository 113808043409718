import _ from 'underscore'
import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    this.element.classList.add('scrollable-nav', 'is-left')
    this.appendArrows()
    this.checkScrollPos()
  }

  appendArrows () {
    var left      = document.createElement('a')
    var leftSpan  = document.createElement('span')

    var right     = document.createElement('a')
    var rightSpan = document.createElement('span')

    left.appendChild(leftSpan)
    left.className = 'scroll-left'
    left.setAttribute('data-action', 'click->scrollable-list#scrollLeft')

    right.appendChild(rightSpan)
    right.className = 'scroll-right'
    right.setAttribute('data-action', 'click->scrollable-list#scrollRight')

    if (!this.element.querySelector('.scroll-left') && !this.element.querySelector('.scroll-right')) {
      this.element.appendChild(left)
      this.element.appendChild(right)
    }

    this.toggleVisibility()
  }

  checkScrollPos () {
    var list = this.element.querySelector('ul, ol')

    if (list.scrollLeft == 0) {
      this.element.classList.add('is-left')
    } else if (list.scrollLeft > 0) {
      this.element.classList.remove('is-left')
    }

    if (list.scrollWidth > list.clientWidth) {
      if (list.scrollLeft == list.scrollWidth - list.clientWidth) {
        this.element.classList.add('is-right')
      } else if (list.scrollLeft < list.scrollWidth - list.clientWidth) {
        this.element.classList.remove('is-right')
      }
    }

    this.toggleVisibility()
  }

  toggleVisibility () {
    if (this.element.clientWidth < this.element.querySelector('ul, ol').scrollWidth) {
      this.element.querySelector('.scroll-left').classList.remove('is-hidden')
      this.element.querySelector('.scroll-right').classList.remove('is-hidden')
    } else {
      this.element.querySelector('.scroll-left').classList.add('is-hidden')
      this.element.querySelector('.scroll-right').classList.add('is-hidden')
    }
  }

  scrollLeft () {
    var scrollDistance = parseInt(this.element.getAttribute('data-scroll-distance'), 10)

    event.preventDefault()
    this.element.querySelector('ul, ol').scrollLeft -= scrollDistance
  }

  scrollRight () {
    var scrollDistance = parseInt(this.element.getAttribute('data-scroll-distance'), 10)

    event.preventDefault()
    this.element.querySelector('ul, ol').scrollLeft += scrollDistance
  }
}
