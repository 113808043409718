import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [ 'input', 'output' ]

  get value ()  { return this.inputTarget.value }
  set value (x) { this.inputTarget.value = x    }

  connect () {
    this.updateOutput()
  }

  add () {
    this.value++
    this.updateOutput()
  }

  remove () {
    if (this.value > 1) {
      this.value--
      this.updateOutput()
    }
  }

  updateOutput () {
    if (this.hasOutputTarget) {
      this.outputTarget.innerHTML = this.value
    }
  }
}
