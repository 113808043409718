import $ from 'jquery'
require('../../lib/jquery.autoresize')

$(document).on('turbolinks:load', function(e) {
  $('#new_note textarea').autoResize({'extraSpace': 0})

  $('body').on('submit', '#new_note', function (e) {
    let form = $(this)

    // POST the form with jQuery:
    $.ajax({
      type:    'POST',
      url:      form.attr('action'),
      data:     form.serialize(),
      dataType: 'script'
    })

    // Stop the form from submitting:
    e.preventDefault()

    // Disable the inputs:
    form.find('input, textarea, select').attr('disabled', 'disabled')
  })
})
