import $ from 'jquery'
import _ from 'underscore'
import ApplicationController from '../application_controller'

const INDEX_REGEXP = new RegExp('([_\\]]\\[?)([0-9+])(\\]?[_\\[])', 'g')

export default class extends ApplicationController {

  static targets = [ 'widget' ]

  get index    ()   { return this._index  }
  set index (value) { this._index = value }

  get widget () { return $(this.widgetTarget) }

  connect () {
    _.defer(() => {
      this.cleanWidget = this.widget.clone()
      this.index = 0
    })
  }

  add () {
    this.widget.removeAttr(`data-${this.identifier}-target`).after(this.cleanWidget.clone())
    this.index++

    this.widget.find('input[name]').each((i, el) => {
      let input   = $(el)
      let newName = input.attr('name').replace(INDEX_REGEXP, `$1${this.index}$3`)
      input.attr('name', newName)
    })
  }
}
