import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [ 'actionButton' ]

  get expandable () { return this.data.get('expandable') == 'true'       }
  get expanded ()   { return this.element.classList.contains('expanded') }

  connect () {
    this.prepareExpandable()
  }

  prepareExpandable () {
    if (this.expandable) {
      this.expandHTML   = this.element.innerHTML
      this.collapseHTML = this.element.innerHTML

      let el = document.createElement('div')
      el.innerHTML = this.data.get('expandHtml')
      let li = el.getElementsByTagName('li')[0]

      if (li) {
        this.expandHTML = li.innerHTML
      }
    }
  }

  collapseAllDishes () {
    this.findControllers('order-menu--dish').forEach((c) => c.collapse())
  }

  collapse (event) {
    if (event) { event.preventDefault() }

    if (this.expandable) {
      this.element.innerHTML = this.collapseHTML
      this.element.classList.remove('expanded')
    }
  }

  expand (event) {
    if (event) { event.preventDefault() }

    if (this.expandable) {
      this.collapseAllDishes()

      this.element.innerHTML = this.expandHTML
      this.element.classList.add('expanded')
    }
  }

  trigger (event) {
    if (event) { event.preventDefault() }

    if (this.hasActionButtonTarget) {
      this.actionButtonTarget.click()
    }
  }
}
