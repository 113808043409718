import $ from 'jquery'
import _ from 'underscore'
import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static values = {
    label:    { type: String, default: 'Show more' },
    lines:    { type: Number, default:  2          },
    maxWords: { type: Number, default: 50          },
    omission: { type: String, default: '… '        }
  }

  get wrapper () { return $(this.element) }

  get lineHeight () { return parseInt(this.wrapper.css('line-height'))          }
  get text       () { return $.trim(this.wrapper.text().replace(/\s\s+/g, ' ')) }
  get words      () { return this.text.split(' ')                               }

  get original  () { return this.wrapper.find('.shorten-original')  }
  get shortened () { return this.wrapper.find('.shorten-shortened') }

  get maxLineHeight () { this.linesValue * this.lineHeight }

  connect() {
    if (this.wrapper.height() > this.maxLineHeight || this.words.length > this.maxWordsValue) {
      this.shorten()
    } else {
      this.expand()
    }
  }

  shorten() {
    this.wrapper.wrapInner('<div class="shorten-original"></div>')
    this.wrapper.append(`<div class="shorten-shortened"><a data-action="shorten#expand">${this.labelValue}</a></div>`)

    this.original.hide()
    this.shortened.show()

    var numberOfWords = this.maxWordsValue
    var shortenedTextElement = $('<span></span>')
    shortenedTextElement.text(this.words.slice(0, numberOfWords).join(' ') + this.omissionValue)
    this.shortened.prepend(shortenedTextElement)

    while (this.shortened.height() > this.maxLineHeight) {
      numberOfWords -= 2
      shortenedTextElement.text(this.words.slice(0, numberOfWords).join(' ') + this.omissionValue)
    }
  }

  expand() {
    this.shortened.hide()
    this.original.show()
  }
}
