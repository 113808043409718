import _ from 'underscore'
import ApplicationController from '../application_controller'
import Map from '../../lib/maps/map.js.erb'
import VenueMarker from '../../lib/maps/venue_marker'

export default class extends ApplicationController {
  static targets = ['canvas']

  get coordinates () {
    return [
      parseFloat(this.data.get('latitude')),
      parseFloat(this.data.get('longitude'))
    ]
  }

  get zoomlevel () { return parseInt(this.data.get('zoomlevel')) }

  get canvas () {
    if (this.hasCanvasTarget) {
      return this.canvasTarget
    } else {
      return this.element
    }
  }

  connect () {
    _.defer(() => {
      this.map = new Map(this.canvas, {
        center:          this.coordinates,
        zoom:            this.zoomlevel,
        zoomControl:     false,
        dragging:        false,
        doubleClickZoom: false,
        scrollWheelZoom: false
      })

      this.marker = new VenueMarker(this.coordinates)
      this.map.markers = [this.marker]
    })
  }

  disconnect () {
    _.defer(() => this.map.destroy())
  }
}
