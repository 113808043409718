import $ from 'jquery'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['pagination']

  togglePagination(event) {
    var FADE_DURATION = 1

    var input = $(event.currentTarget)
    var cells = $('label[for=' + input.attr('id') + ']').parents('td')

    if (input.is(':checked')) {
      cells.addClass('selected')

      // Hide the links to prev/next month, because we are not saving state:
      $(this.paginationTarget).fadeOut(function () {
        $(this).css({
          visibility: 'hidden'
        }).fadeIn(FADE_DURATION)
      })
    } else {
      cells.removeClass('selected')

      // Show the links for prev/next month back in if no other weeks are selected:
      if ($('.new_venue_promotion .bookable:not(.booked) input[type=checkbox]:checked').length === 0) {
        $(this.paginationTarget).fadeOut(FADE_DURATION, function () {
          $(this).css({
            visibility: 'visible'
          }).fadeIn()
        })
      }
    }
  }
}
