import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static targets = [
    'navigationList',
    'slidebar'
  ]

  static classes = [
    'isVisible',
    'isHidden',
    'itemsHidden'
  ]

  connect () {
    this.removeNavigationItems()
    window.dispatchEvent(new Event('resize'))
  }

  removeNavigationItems () {
    const navigationItems = this.navigationListTarget.querySelectorAll(':scope > li')
    const navigationWidth = this.navigationListTarget.offsetWidth - 30

    let navigationItemsWidth = 0
    let itemsHidden          = false

    navigationItems.forEach(item => {
      item.classList.remove(this.isHiddenClass)
      navigationItemsWidth += item.offsetWidth

      if (navigationItemsWidth > navigationWidth) {
        item.classList.add(this.isHiddenClass)
        itemsHidden = true
      }
    })

    this.element.classList.toggle(this.itemsHiddenClass, itemsHidden)
  }

  toggleSlidebar () {
    event.preventDefault()
    this.slidebarTarget.classList.toggle(this.isVisibleClass)
  }
}