import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['menu']

  get visible () {
    return this.hasMenuTarget &&
           this.menuTarget.classList.contains('is-visible')
  }

  get hidden () {
    return !this.visible
  }

  toggle (event) {
    if (event) event.preventDefault()

    if (this.hidden) this.show()
    else             this.hide()
  }

  show () {
    this.element.classList.add('dropdown--is-visible')
    if (this.hasMenuTarget)
      this.menuTarget.classList.add('is-visible')
  }

  hide (event) {
    if (event && event.target && this.element.contains(event.target)) {
      // event.preventDefault()
      return;
    }

    this.element.classList.remove('dropdown--is-visible')
    if (this.hasMenuTarget)
      this.menuTarget.classList.remove('is-visible')
  }
}
