import jQuery from 'jquery'

(function($) {
  class KitchenSelect {
    constructor(root, options = {}) {
      this.root = root
      
      this.options = options
      this.list = this.root.find('ul')
      
      if (!this.root.hasClass('faux-select')) this.create()
      
      this.refresh()
      this.bindEvents()
    }
    
    create() {
      this.root.addClass('faux-select')
      
      // Append link to "Show all kitchens"
      this.list.after(`
        <a href="#" class="show-all-kitchens">${
          I18n.t('shared.filters.kitchens.kitchen_select_show_all_kitchens')
        }</a>
      `)
      
      // Add the input that you can type in to filter results:
      this.list.before(`
        <div class="faux-select-form">
          <div class="faux-select-fieldset">
            <input type="text" class="faux-select-input" placeholder="${I18n.t('shared.filters.kitchens.kitchen_select_placeholder')}" />
          </div>
        </div>
      `)
      
      // Add list with results that can be filtered:
      this.list.after(`
        <div class="faux-select-options">
          <ul>
          </ul>
        </div>\
      `)
      
      this.list.addClass('hidden')
    }
    
    refresh() {
      this.selectForm     = this.root.find('.faux-select-form')
      this.selectFieldset = this.selectForm.find('.faux-select-fieldset')
      this.selectInput    = this.selectForm.find('.faux-select-input')
      
      this.selectOptions = this.root.find('.faux-select-options')
      this.optionsList   = this.selectOptions.find('ul')
      
      // Remove all faux options to restart:
      this.optionsList.find('.faux-select-option').remove()
      
      // Add and hide the 'No results' item:
      this.optionsList.append(`
        <li class="faux-select-no-results faux-select-option">
          <a href="#">${I18n.t('shared.filters.kitchens.kitchen_select_no_results')}</a>
        </li>\
      `)
      
      this.noResults = this.optionsList.find('.faux-select-no-results')
      this.noResults.hide()
      
      // Append all options in list to faux-select list:
      this.list.find('li').addClass('faux-select-option').clone().appendTo(this.optionsList)
      
      // Show active options as selected in faux-select-input:
      this.optionsList.find('.active').each((index, anchor) => this.selectOption($(anchor)))
      this.updateSelectedOptions()
    }
    
    toggleOption(anchor) {
      let kitchen = anchor.data('kitchen')
      let option  = this.optionsList.find(`[data-kitchen='${kitchen}']`).closest('.faux-select-option')
      
      if (option.is('.is-selected')) {
        this.removeOption(anchor)
      } else {
        this.selectOption(anchor)
      }
    }
    
    removeOption(anchor) {
      let kitchen = anchor.data('kitchen')
      let option  = this.optionsList.find(`[data-kitchen='${kitchen}']`).closest('.faux-select-option')
      option.removeClass('is-selected')
      
      this.updateSelectedOptions()
      this.closeDropdown()
    }
    
    selectOption(anchor) {
      let kitchen = anchor.data('kitchen')
      let option  = this.optionsList.find(`[data-kitchen='${kitchen}']`).closest('.faux-select-option')
      option.addClass('is-selected')
      
      this.updateSelectedOptions()
      this.closeDropdown()
    }
      
    updateSelectedOptions() {
      // Clear selected options first:
      this.selectForm.find('.faux-selected-option').remove()
      
      this.optionsList.find('.is-selected').each((index, option) => {
        let anchor = $(option).find('a')
        
        $(`
          <div class="faux-selected-option" data-kitchen="${anchor.data('kitchen')}">
            ${anchor.html()}
            <a class="faux-select-remove" data-action="click->results-filter#applyFilter" href="${anchor.attr('href')}"></a>
          </div>
        `).insertBefore(this.selectFieldset)
      })
    }
    
    removeLastSelectedOption() {
      let lastSelectedOption = this.selectFieldset.prev('.faux-selected-option').last()
      lastSelectedOption.find('a').click()
    }
    
    showDropdown() {
      this.selectOptions.addClass('is-visible')
      // this.resizeDropdown()
    }
    
    resizeDropdown() {
      if (!this.selectOptions.hasClass('is-visible')) { return }
      
      if (($(window).width() <= 1279) && ($(window).width() >= 768)) {
        this.selectOptions.css({'min-height': 0, 'max-height': 'none'})
        
        let minHeight   = 12 + (48 * 4)
        let topPosition = this.selectForm.offset().top - $(window).scrollTop()
        
        // Make sure max-height is never bigger then available space or smaller then min-height:
        let maxHeight = $(window).height() - topPosition - 48 - this.selectForm.height()
        maxHeight     = Math.max(minHeight, maxHeight)
        
        // Make sure max-height/min-height are never bigger then the height of the options:
        maxHeight   = Math.min(maxHeight, this.selectOptions.height())
        minHeight   = Math.min(minHeight, maxHeight)
        
        this.selectOptions.css({'min-height': minHeight, 'max-height': maxHeight})
      } else {
        this.selectOptions.removeAttr('style')
      }
    }
    
    closeDropdown() {
      this.selectInput.blur().html('')
      this.selectOptions.removeClass('is-visible')
    }
    
    filterAvailableOptions() {
      let query   = $.trim(this.selectInput.val())
      let regexp  = new RegExp(`^(?=.*\\b${query.split(/\s+/).join('\\b)(?=.*\\b')}).*$`, 'i')
      let results = this.selectOptions.find('.faux-select-option')
      
      results.show().filter(function() {
        let text = $(this).text().replace(/\s+/g, ' ')
        return !regexp.test(text)
      }).hide()
      
      let visibleResults = this.selectOptions.find('.faux-select-option:visible')
      if (visibleResults.length === 0) {
        this.noResults.show()
      } else {
        this.noResults.hide()
      }
      
      // this.resizeDropdown()
    }
      
      
    
    bindEvents() {
      // $(window).on('resize', this.resizeDropdown.bind(this))
      
      $(document).on('click', '.show-all-kitchens', (event) => {
        event.preventDefault()
        this.selectInput.val('').focus()
        this.filterAvailableOptions()
      })
      
      // Open dropdown when input is focused:
      $(document).on('focus', '.faux-select-input', (event) => {
        if (this.root.has(event.target).length) this.showDropdown()
        this.noResults.hide()
      })
      
      // Close dropdown when clicking anywhere else on the page:
      $(document).on('click touchend', (event) => {
        if (!this.root.is(event.target) && !this.root.has(event.target).length) {
          this.closeDropdown()
          this.selectInput.val('')
          this.filterAvailableOptions()
        }
      })
      
      // Toggle options when clicked:
      $(document).on('click touchend', '.faux-select-option', (index, item) => {
        item = $(item)
        if (this.root.has(item).length) this.toggleOption(item.find('a'))
      })
      
      // Remove already selected option:
      $(document).on('click touchend', '.faux-select-remove', (event) => {
        let item = $(event.currentTarget).closest('.faux-selected-option')
        if (this.root.has(item).length) this.removeOption(item.find('a'))
      })
      
      // Search through available options:
      $(document).on('keyup', '.faux-select-input', (event) => {
        if (this.root.has(event.currentTarget).length) this.filterAvailableOptions()
      })
      
      // WIP: Buggy
      // Remove last selected option when pressing Backspace when there is no query:
      // $(document).on 'keydown', '.faux-select-input', (event) =>
      //  if event.keyCode == 8 && @root.has(event.currentTarget).length
      //    query = $.trim @selectInput.text()
      //    @removeLastSelectedOption() if query == ""
    }
  }
  
  $.fn.extend({
    kitchenSelect: function(opts = {}) {
      let defaultOptions = {}
      
      return this.map(function(index, element) {
        let kitchenSelect
        let options = $.extend({}, defaultOptions, opts)
        let root    = $(element)
        
        if (kitchenSelect = root.data('kitchen-select')) {
          kitchenSelect.refresh()
        } else {
          kitchenSelect = new KitchenSelect(root, options)
          $(this).data('kitchen-select', kitchenSelect)
        }
        
        return kitchenSelect
      })
    }
  })
})(jQuery)
