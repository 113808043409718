import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'checkbox', 'content' ]

  connect () {
    this.check()
  }

  check () {
    if (this.checkboxTarget.checked) {
      this.show()
    } else {
      this.hide()
    }
  }

  hide () {
    this.contentTarget.classList.add('is-hidden')
  }

  show () {
    this.contentTarget.classList.remove('is-hidden')
  }
}
