import ApplicationController from './application_controller'

export default class extends ApplicationController {
  copy () {
    var status        = document.createElement('span')
    var statusMessage = 'Gekopieerd'

    this.element.focus()
    this.element.setSelectionRange(0,99999)

    try {
      document.execCommand('copy')
      status.append(statusMessage)
      this.element.closest('.clipboard').prepend(status)
    } catch (error) {
      // alert('Error message')
    }
  }
}
