import $ from 'jquery'

$(document).on('click', '.opening-hours-parse', function() {
  var textarea = $('#opening_hours_parser_text');
  
  var text = textarea.val();
  var url  = textarea.data('parse-url');
  
  $.get(url, { text: text }, function(data) {
    data['days'].forEach(function(day, index) {
      $('#venue_opening_days_attributes_' + index + '_lunch_till').val(day['lunch_till']);
      $('#venue_opening_days_attributes_' + index + '_lunch_from').val(day['lunch_from']);
      
      $('#venue_opening_days_attributes_' + index + '_dinner_from').val(day['dinner_from']);
      $('#venue_opening_days_attributes_' + index + '_dinner_till').val(day['dinner_till']);
      
      $('#venue_opening_days_attributes_' + index + '_closed').prop('checked', day['closed']);
    });
  });
});
