import ApplicationController from '../application_controller'
import _ from 'underscore'
import $ from 'jquery'

require('../../lib/jquery.kitchen-select')

export default class extends ApplicationController {
  connect () {
    _.defer(() => $(this.element).kitchenSelect())
  }
}
