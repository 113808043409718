import jQuery from 'jquery'

(function($) {
  class AdvancedSearchForm {
    constructor(form, options = {}) {
      this.form      = form
      this.options   = options
      this.templates = this.options.templates
      
      this.bindEvents()
    }
    
    addSearchFieldsClickEvent(event) {
      let target = $(event.currentTarget)
      this.addFields(target, target.data('field-type'), target.data('content'))
      event.preventDefault()
    }
    
    nestSearchFieldsClickEvent(event) {
      let target = $(event.currentTarget)
      this.nestFields(target, target.data('field-type'))
      event.preventDefault()
    }
    
    removeSearchFieldsClickEvent(event) {
      let target = $(event.currentTarget)
      this.removeFields(target)
      event.preventDefault()
    }
    
    combinatorChangeEvent(event) {
      let target = $(event.currentTarget);
      this.changeCombinator(target)
    }
    
    bindEvents() {
      this.form.on('click', '.add-search-fields',    this.addSearchFieldsClickEvent.bind(this))
      this.form.on('click', '.remove-search-fields', this.removeSearchFieldsClickEvent.bind(this))
      this.form.on('click', '.nest-search-fields',   this.nestSearchFieldsClickEvent.bind(this))
    
      this.form.on('change', '.advanced-search-combinator select', this.combinatorChangeEvent.bind(this))
      this.form.find('.advanced-search-combinator select').trigger('change');
    }
    
    changeCombinator(select) {
      let group = select.closest('.advanced-search-grouping')
      let value = select.val()
      
      switch (value) {
        case 'and': group.removeClass('advanced-search-or-grouping').addClass('advanced-search-and-grouping')
        case 'or':  group.removeClass('advanced-search-and-grouping').addClass('advanced-search-or-grouping')
        default:    group.removeClass('advanced-search-and-grouping').removeClass('advanced-search-or-grouping')
      }
    }
    
    removeFields(button) {
      button.closest('.advanced-search-fields').remove()
    }
    
    addFields(button, type, content) {
      let new_id = new Date().getTime()
      let regexp = new RegExp(`new_${type}`, 'g')
      let result = content.replace(regexp, new_id)
      
      button.before(result)
    }
    
    nestFields(button, type) {
      let template = this.templates[type]
      
      let newID  = new Date().getTime()
      let regexp = new RegExp(`new_${type}`, 'g')
      
      let unsanitizedObjectName = button.closest('.advanced-search-fields').data('object-name')
      let sanitizedObjectName   = unsanitizedObjectName.replace(/\]\[|[^-a-zA-Z0-9:.]/g, '_').replace(/_$/, '')
      
      let result = template;
      result = result.replace(/new_object_name\[/g, unsanitizedObjectName + "[")
      result = result.replace(/new_object_name_/,   sanitizedObjectName   + '_')
      result = result.replace(regexp, newID)
      
      button.before(result)
      this.form.find('.advanced-search-combinator select').trigger('change')
    }
  }
  
  $.fn.extend({
    advancedSearchForm: function (opts) {
      let defaultOptions = { }
      
      this.each((index, element) => {
        let options = $.extend(true, {}, defaultOptions, opts)
        let wrapper = $(this)
        
        return new AdvancedSearchForm(wrapper, options)
      })
    }
  })
})(jQuery)