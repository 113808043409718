import _ from 'underscore'
import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [ 'link' ]

  connect () {
    if (location.hash) {
      let hash = location.hash
      let link = document.querySelector(`a[href="${hash}"]`)
      if (link) link.classList.add('selected')
    }
  }

  scrollTo (event) {
    let targetId      = event.currentTarget.getAttribute('href').replace(/^(.+)?#/, '')
    let targetElement = document.getElementById(targetId)

    if (targetElement) {
      let targetPos = targetElement.offsetTop
      let scrollPos = targetPos - 66

      event.preventDefault()

      location.hash = "#" + targetId

      targetElement.tabIndex = -1
      targetElement.focus()

      window.scrollTo(0, scrollPos)

      this.linkTargets.forEach(function(link) {
        link.classList.remove('selected')
      })

      event.currentTarget.classList.add('selected')
    }
  }

  checkPosition () {
    this.linkTargets.forEach(function(link) {
      let targetId      = link.getAttribute('href').replace(/^(.+)?#/, '')
      let targetElement = document.getElementById(targetId)

      if (targetElement) {
        let elementHeight    = targetElement.clientHeight
        let elementTopPos    = targetElement.offsetTop - 70
        let elementBottomPos = elementTopPos + elementHeight
        let scrollPos        = window.scrollY

        if (scrollPos > elementTopPos && scrollPos < elementBottomPos) {
          link.classList.add('selected')
        } else {
          link.classList.remove('selected')
        }
      }
    })
  }
}
