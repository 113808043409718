import _ from 'underscore'
import $ from 'jquery'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['label', 'labelInput', 'requiredInput', 'multipleChoiceInput', 'destroyInput', 'addOptionLink']

  get dish ()    { return this.findParentController('menu-editor--dish')   }
  get options () { return this.findChildControllers('menu-editor--option') }

  get label () {
    return this.hasLabelInputTarget && this.labelInputTarget.value
  }
  set label (newLabel) {
    if (this.hasLabelInputTarget) {
      this.labelInputTarget.value = newLabel
    }
  }

  get required () {
    return this.hasRequiredInputTarget && this.requiredInputTarget.checked
  }
  set required (requiredTrueOrFalse) {
    if (this.hasRequiredInputTarget) {
      this.requiredInputTarget.checked = requiredTrueOrFalse
    }
  }

  get multipleChoice () {
    return this.hasMultipleChoiceInputTarget && this.multipleChoiceInputTarget.checked
  }
  set multipleChoice (multipleChoiceTrueOrFalse) {
    if (this.hasMultipleChoiceInputTarget) {
      this.multipleChoiceInputTarget.checked = multipleChoiceTrueOrFalse
    }
  }

  get destroyed () {
    return this.hasDestroyInputTarget && this.destroyInputTarget.checked
  }
  set destroyed (trueOrFalse) {
    if (this.hasDestroyInputTarget) {
      this.destroyInputTarget.checked = trueOrFalse
    }
  }

  get asJSON () {
    return {
      label:          this.label,
      required:       this.required,
      multipleChoice: this.multipleChoice,
      options:        this.options.map((o) => o.asJSON)
    }
  }

  get dishTitle () {
    let title = _.result(this.dish, 'title', '').trim()
    if (title.length < 1) title = '...'
    return title
  }

  get labelValue () {
    let label = _.result(this.labelInputTarget, 'value', '').trim()
    if (label.length < 1) label = '...'
    return label
  }

  get labelTemplate () {
    let tmpl = _.result(this.labelTarget, 'dataset', {}).labelTemplate
    if (tmpl == null) tmpl = '...'
    return tmpl
  }

  get isCollapsed () { return this.element.classList.contains(this.collapseClass) }
  get isExpanded ()  { return !this.isCollapsed }

  get collapseClass () { return 'menu-form-option-group-collapsed' }

  connect () {
    _.defer(() => this.expandOrCollapse())
  }

  addOption (option) {
    if (option.destroyed) { return }

    if (this.hasAddOptionLinkTarget) {
      let optionIndex = this.options.length

      this.addOptionLinkTarget.onclick()

      _.defer(() => {
        let newOption = this.options[optionIndex]

        newOption.description = option.description
        newOption.price       = option.price
      })
    }
  }

  expandOrCollapse () {
    if (this.isCollapsed) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  expand () {
    this.element.classList.remove(this.collapseClass)
    this.resetLabel()
  }

  collapse () {
    this.element.classList.add(this.collapseClass)
    this.resetLabel()
  }

  resetLabel () {
    if (this.hasLabelTarget && this.hasLabelInputTarget) {
      if (this.isCollapsed) {
        this.labelTarget.innerHTML = this.labelValue
      } else {
        this.labelTarget.innerHTML = this.labelTemplate.replace('{{dish}}', this.dishTitle)
      }
    }
  }

  toggle () {
    if (this.isCollapsed) {
      this.expand()
    } else {
      this.collapse()
    }
  }
}
