import jQuery from 'jquery'

(function($) {
  class PricingSelect {
    constructor(filter, options) {
      // Hide the <ul> that is used as a fallback:
      this.filter = filter
      this.options = options
      this.filter.find('ul').hide()
    
      if (!this.filter.hasClass('pricing-select')) { this.insertPriceRanges() }
      this.bindEvents()
    
      this.filter.addClass('pricing-select')
    }
    
    insertPriceRanges() {
      return this.filter.find('li').each((index, element) => {
        let listItem = $(element)
        let isActive = listItem.find('a').is('.active')
        let price    = listItem.data('price')
        let title    = listItem.data('currency-symbols')
      
        let priceRange = $(`<div class='price-range' data-price='${price}'>${title}</div>`)
        if (isActive) { priceRange.addClass('is-selected') }
        return priceRange.insertBefore(this.filter.find('ul'))
      })
    }
    
    bindEvents() {
      this.filter.on('click', '.price-range', (event) => {
        let range = $(event.currentTarget)
        let price = range.data('price')
      
        // Update class of selected price range:
        if (range.hasClass('is-selected')) {
          range.removeClass('is-selected')
        } else {
          this.filter.find('.price-range').removeClass('is-selected')
          range.addClass('is-selected')
        }
      
        // Trigger the click event of the corresponding price range:
        let filterLink = document.querySelector(`ul [data-price='${price}'] a`)
        let clickEvent = new Event('click', { bubbles: true, cancelable: true })
        
        filterLink.dispatchEvent(clickEvent)
      })
    }
  }
  
  $.fn.extend({
    pricingSelect: function(opts = {}) {
      let defaultOptions = {}
      
      return this.map(function(index, element) {
        let options = $.extend({}, defaultOptions, opts)
        let select  = $(element)
      
        let pricingSelect = select.data('pricing-select') || new PricingSelect(select, options)
        select.data('pricing-select', pricingSelect)
        
        return pricingSelect
      })
    }
  })
})(jQuery)
