// Source: https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API

import ApplicationController from './application_controller'
import _ from 'underscore'
import Rails from '@rails/ujs'
import { currentUser } from '../models/current_user'

export default class extends ApplicationController {
  get defaultPollingDelay() {
    return parseInt(this.data.get('pollingDelay') || 10)
  }

  get supportsNotificationPromise () {
    try {
      Notification.requestPermission().then()
      return true
    } catch (e) {
      return false
    }
  }

  get canNotifyViaBrowser () {
    return ('Notification' in window) &&
           (Notification.permission == 'granted')
  }

  connect () {
    this.subscriptions = {}
    this.loadUser()
  }

  disconnect () {
    this.clearSubscriptions()
  }

  loadUser () {
    this.user = null
    currentUser.fetch().done(() => {
      let user = this.user = currentUser.user
      if (user && user.subscriptions) {
        for (const [url, delay] of Object.entries(user.subscriptions)) {
          if (!this.hasSubscription(url)) {
            this.subscribe(url, delay)
          }
        }
      }
    }).fail(() => {
      this.clearSubscriptions()
    })
  }

  hasSubscription (url) {
    return (this.subscriptions[url] != null)
  }

  subscribe (url, pollingDelay = null) {
    if (pollingDelay == null) {
      pollingDelay = this.defaultPollingDelay
    }

    this.requestPermission()

    this.clearSubscription(url)

    this.subscriptions[url] = setInterval(
      () => { this.fetchSubscription(url) },
      pollingDelay * 1000
    )
  }

  clearSubscription (url) {
    if (this.hasSubscription(url)) {
      clearInterval(this.subscriptions[url])
      delete this.subscriptions[url]
    }
  }

  clearSubscriptions () {
    for (const [url, timeout] of Object.entries(this.subscriptions)) {
      this.clearSubscription(url)
    }
  }

  fetchSubscription (url) {
    Rails.ajax({
      url:      url,
      type:     'GET',
      dataType: 'script',
      complete: (response) => this.handleSubscription(url, response)
    })
  }

  handleSubscription (url, response) {
    // TODO: Maybe add some code here later
  }

  handlePermission (permission) {
    // Whatever the user answers, we make sure Chrome stores the information
    if (!('permission' in Notification)) {
      Notification.permission = permission
    }
  }

  requestPermission () {
    if (!('Notification' in window) || Notification.permission) {
      return
    }

    // check promise based Notifications API
    if (this.supportsNotificationPromise) {
      Notification.requestPermission().then(this.handlePermission)
    } else {
      Notification.requestPermission(this.handlePermission)
    }
  }

  notifyBrowser (options) {
    if (this.canNotifyViaBrowser) {
      let notification = new Notification(options.title, {
        body: options.text,
        icon: options.image
      })

      if (options.url) {
        notification.onclick = (event) => {
          event.preventDefault()
          window.open(options.url, '_blank')
        }
      }
    }
  }

  notify (options) {
    this.notifyBrowser(options)
  }
}
