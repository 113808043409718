import $ from 'jquery'
import URI from 'urijs'
import L from 'leaflet'
import _ from 'underscore'
import ClusterMap from '../lib/maps/cluster_map'
import ResultMarker from '../lib/maps/result_marker'
import ApplicationController from './application_controller'

const BOUNDS_PADDING_RATIO  = -0.05

export default class extends ApplicationController {

  static targets = ['filter', 'results', 'result', 'pin', 'container', 'canvas', 'searchAreaButton']

  get container () {
    if (this.hasContainerTarget) {
      return this.containerTarget
    } else {
      return this.element
    }
  }

  get canvas () {
    if (this.hasCanvasTarget) {
      return this.canvasTarget
    } else {
      return this.element
    }
  }

  get leaflet () {
    return this.map.leaflet
  }

  get origin ()    { return this.data.get('origin').split(',').map((c) => parseFloat(c)) }
  get zoomlevel () { return parseInt(this.data.get('zoomlevel')) }

  get visibleMarkers () {
    return this.markers.filter((marker) => this.isElementInViewport(marker.result))
  }

  get markers ()  {
    if (this.map) {
      return this.map.markers
    } else {
      return []
    }
  }

  set markers (v) { this.map.markers = v }

  get mapCenter ()    { return this.leaflet.getCenter() }
  get mapBounds ()    { return this.leaflet.getBounds().pad(BOUNDS_PADDING_RATIO) }
  get mapZoomlevel () { return this.leaflet.getZoom() }

  connect () {
    _.defer(() => {
      this.setupMap()
      this.addMarkers()
      this.updateMap()
    })
  }

  disconnect () {
    if (this.map) {
      this.map.destroy()
    }
  }

  setupMap () {
    this.map = new ClusterMap(this.canvas, {
      center:      this.origin,
      zoom:        this.zoomlevel,
      zoomControl: false
    })

    // Add zoom control:
    L.control.zoom({
      zoomInText:  '+',
      zoomOutText: '-',
      position:    'topright'
    }).addTo(this.map.leaflet)

    this.map.on('moveend', this.updateSearchAreaButton.bind(this))
    this.updateSearchAreaButton()
  }

  addMarkers () {
    this.markers = this.pinTargets.map((pin) => new ResultMarker(pin))
    this.highlightVisibleMarkers()
  }

  // PUBLIC ACTIONS:

  popupOpened (event) {
    let marker = event.detail.marker
    let pin    = marker.result
    let result = this.resultForPin(pin)

    if (!this.isElementInViewport(result)) {
      let position = $(result).offset().top

      $('body, html').animate({ scrollTop: position }, 300)
    }
  }

  popupClosed (event) {
    _.defer(this.updateMap.bind(this))
  }

  mouseOverMarker (event) {
    let result = this.resultForMarkerEvent(event)
    if (result) result.classList.add('hover-on-marker')
  }

  mouseOutMarker (event) {
    let result = this.resultForMarkerEvent(event)
    if (result) result.classList.remove('hover-on-marker')
  }

  mouseOverResult (event) {
    let marker = this.markerForResultEvent(event)
    if (marker) marker.highlight()
  }

  mouseOutResult (event) {
    let marker = this.markerForResultEvent(event)
    if (marker) marker.unhighlight()
  }

  updateMap () {
    if (this.map) {
      this.setContainerDimensions()

      if (this.isVisible(this.container)) {
        this.makeMapVisible()
        this.updateSearchAreaButton()

        if (!this.map.hasOpenPopup) {
          this.highlightVisibleMarkers()
        }
      } else {
        this.makeMapInvisible()
      }
    }
  }

  // (PRIVATE) ACTIONS:

  isVisible (elem) {
    if (!(elem instanceof Element)) throw Error('DomUtil: elem is not an element.')

    const style = window.getComputedStyle(elem)

    if (style.display === 'none')       return false
    if (style.visibility !== 'visible') return false
    if (style.opacity < 0.1)            return false

    if (elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height + elem.getBoundingClientRect().width === 0) {
      return false
    }

    const elemCenter = {
      x: elem.getBoundingClientRect().left + elem.offsetWidth / 2,
      y: elem.getBoundingClientRect().top  + elem.offsetHeight / 2
    }

    if (elemCenter.x < 0) return false

    if (elemCenter.x > (document.documentElement.clientWidth || window.innerWidth)) return false

    if (elemCenter.y < 0) return false

    if (elemCenter.y > (document.documentElement.clientHeight || window.innerHeight)) return false

    let pointContainer = document.elementFromPoint(elemCenter.x, elemCenter.y)

    do {
      if (pointContainer === elem) return true
    } while (pointContainer = pointContainer.parentNode)

    return false
  }

  makeMapVisible () {
    if (this.map) {
      this.map.addTileLayer()
    }
  }

  makeMapInvisible () {
    if (this.map) {
      this.map.removeTileLayer()
    }
  }

  setContainerDimensions () {
    let scrollTop    = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
    let headerOffset = this.element.offsetTop
    let venuesOffset = this.resultsTarget.offsetTop + headerOffset

    if (window.innerWidth > this.filterTarget.clientWidth && !this.element.classList.contains('filters-toggled')) {
      if (scrollTop < headerOffset) {
        this.container.style.cssText = 'position: absolute; top:' + headerOffset + 'px;'
      } else {
        this.container.style.cssText = 'position: fixed; top: 0;'
      }
    } else if (!this.element.classList.contains('filters-toggled')) {
      if (scrollTop < venuesOffset) {
        this.container.style.cssText = 'position: absolute; top:' + venuesOffset + 'px;'
      } else {
        this.container.style.cssText = 'position: fixed; top: 0;'
      }
    }

    this.updateSearchAreaButton()
  }

  updateSearchAreaButton () {
    if (this.hasSearchAreaButtonTarget) {
      let button = this.searchAreaButtonTarget

      let searchURL = URI(button.getAttribute('href'))
      let southWest = this.mapBounds.getSouthWest()
      let northEast = this.mapBounds.getNorthEast()

      searchURL.setQuery('geo_bounds_sw', `${southWest.lat},${southWest.lng}`)
      searchURL.setQuery('geo_bounds_ne', `${northEast.lat},${northEast.lng}`)
      searchURL.setQuery('origin',        `${this.mapCenter.lat},${this.mapCenter.lng}`)
      searchURL.setQuery('zoomlevel',      this.mapZoomlevel.toString())

      button.setAttribute('href', searchURL.toString())
      button.classList.add('visible')
    }
  }

  highlightVisibleMarkers () {
    this.map.activeMarkers = this.visibleMarkers
  }

  // HELPERS:

  resultForMarkerEvent (event) {
    if (event.currentTarget) {
      let markerNode = $(event.currentTarget).closest(`[data-results-map-target*="marker"]`)[0]

      let marker = this.markerWithId(markerNode.dataset.resultId)
      let pin    = this.pinForMarker(marker)

      return this.resultForPin(pin)
    }
  }

  markerForResultEvent (event) {
    if (event.currentTarget) {
      let result = $(event.currentTarget).closest(`[data-${this.identifier}-target*="result"]`)
      let pin    = this.pinForResult(result)

      return this.markerForPin(pin)
    }
  }

  resultForPin (pin) {
    return $(pin).parents(`[data-${this.identifier}-target*="result"]`)[0]
  }

  pinForResult (result) {
    return $(result).find(`[data-${this.identifier}-target*="pin"]`)[0]
  }

  markerForPin (pin) {
    return this.markerWithId(pin.dataset.markerId)
  }

  pinForMarker (marker) {
    return this.pinWithId(marker.resultId)
  }

  markerWithId (id) {
    for (let marker of this.markers) {
      if (`${marker.resultId}` == `${id}`) {
        return marker
      }
    }
  }

  pinWithId (id) {
    for (let pin of this.pinTargets) {
      if (`${pin.dataset.markerId}` == `${id}`) {
        return pin
      }
    }
  }

  isElementInViewport (element) {
    let rect   = element.getBoundingClientRect()
    let height = window.innerHeight || document.documentElement.clientHeight
    let width  = window.innerWidth  || document.documentElement.clientWidth

    return (
      rect.bottom >= 0      &&
      rect.left   >= 0      &&
      rect.top    <= height &&
      rect.left   <= width
    )
  }
}
