import rangeSlider from 'rangeslider-js'
import _ from 'underscore'
import $ from 'jquery'
import ApplicationController from '../application_controller'

const RATING_INPUT = '<input type="range" min="0" max="100" step="10" value="0">'

export default class extends ApplicationController {

  static targets = [
    'output',
    'title',
    'list',
    'rating'
  ]

  static values = {
    min:     { type: Number, default:   0 },
    max:     { type: Number, default: 100 },
    step:    { type: Number, default:  10 },
    current: { type: Number, default:   0 }
  }

  get sliderHandler () {
    this.ratingInput['rangeslider-js']
  }

  get ratingInput () {
    let input = this.outputTarget.querySelector('input[type=range]')
    if (!input) {
      this.outputTarget.innerHTML = RATING_INPUT
      input = this.outputTarget.querySelector('input[type=range]')
    }

    return input
  }

  get title () {
    this.titleTarget.innerHTML
  }

  set title (newTitle) {
    this.titleTarget.innerHTML = newTitle
  }

  connect() {
    this.ratingInput.value = this.currentValue
    this.ratingInput.min   = this.minValue
    this.ratingInput.max   = this.maxValue
    this.ratingInput.step  = this.stepValue

    let initialRating = this.findRatingByValue(this.currentValue)
    if (initialRating)
      this.titleTarget.innerHTML = initialRating.getAttribute('data-filters--rating-slider-title')

    _.defer(() => this.createSlider())
  }

  createSlider() {
    if (!this.element.classList.contains('has-slider')) {
      this.element.classList.add('has-slider')
      rangeSlider.create(
        this.ratingInput, {
        value:      this.currentValue,
        polyfill:   false,
        onSlide:    this.slide.bind(this),
        onSlideEnd: this.land.bind(this)
      })
    }
  }

  disconnect() {
    if (this.sliderHandler) this.sliderHandler.destroy()
    this.outputTarget.innerHTML = ''
    this.element.classList.remove('has-slider')
  }

  findRatingByValue(value) {
    return this.ratingTargets.find((el) => el.getAttribute('data-filters--rating-slider-rated') == value)
  }

  slide(value, percent, position) {
    let rating = this.findRatingByValue(value)
    if (rating) {
      this.title = rating.getAttribute('data-filters--rating-slider-title')
    }
  }

  land(value, percent, position) {
    if (value != this.currentValue) {
      let rating = this.findRatingByValue(value)
      let link   = rating.querySelector('a')

      if (link) {
        let clickEvent = new Event('click', { bubbles: true, cancelable: true })
        link.dispatchEvent(clickEvent)
      }
    }
  }
}
