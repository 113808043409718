`import $ from 'jquery';`

FILTER   = '.venue-results-filter'
FILTER_LINK = 'a[data-filter-link=true]'

# Persistent state across requests
pageXOffset  = null
pageYOffset  = null
filterActive = false

$(document).on 'click', "a", (event) ->
  if $(event.target).is(FILTER_LINK)
    pageXOffset = window.pageXOffset
    pageYOffset = window.pageYOffset
    
    filterActive = $(FILTER).hasClass('is-active')
  else
    pageXOffset = null
    pageYOffset = null
    
    filterActive = false
  
  true

$(document).on 'turbolinks:render', (event) ->
  filter = $(FILTER)
  
  if filter.length > 0 && filterActive
    window.scrollTo pageXOffset, pageYOffset if pageXOffset? && pageXOffset?

$(document).on 'turbolinks:load', (event) ->
  pageXOffset  = null
  pageYOffset  = null
  filterActive = false
