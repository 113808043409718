import $ from 'jquery'
import _ from 'underscore'
import ApplicationController from '../application_controller'

const GEOLOCATOR_LABEL      = '.geolocator-label'
const GEOLOCATOR_LABEL_HTML = '<label class="geolocator-label" data-forms--geolocate-target="textLabel"></span>'

const GEOLOCATOR_ICON       = '.geolocator-icon'
const GEOLOCATOR_ICON_HTML  = '<span class="geolocator-icon" data-action="click->forms--geolocate#fetchCurrentLocation"></span>'

export default class extends ApplicationController {

  static targets = [ 'form', 'latInput', 'lngInput', 'textInput', 'textLabel' ]

  get geocoder    () { return window.google && window.google.maps && window.google.maps.Geocoder }
  get geolocation () { return navigator && navigator.geolocation }

  get supportsGeocoding   () { return !!this.geocoder                       }
  get supportsGeolocation () { return !!this.geolocation.getCurrentPosition }

  get textValue ()      { if (this.hasTextInputTarget) return this.textInputTarget.value  }
  set textValue (value) { if (this.hasTextInputTarget) this.textInputTarget.value = value }

  get currentLatitude ()      { return this.hasLatInputTarget && this.latInputTarget.value    }
  set currentLatitude (value) { if (this.hasLatInputTarget) this.latInputTarget.value = value }

  get currentLongitude ()      { return this.hasLngInputTarget && this.lngInputTarget.value    }
  set currentLongitude (value) { if (this.hasLngInputTarget) this.lngInputTarget.value = value }

  get currentLocationLabel       () { return this.data.get('currentLocationLabel') }
  get textInputIsCurrentLocation () {
    if (!this.hasTextInput) return false

    let value = this.textValue.toLowerCase()
    if (value.length == 0) return false

    if (value == `${this.currentLocationLabel}`.toLowerCase())        return true
    if (value == `${this.currentLatitude}, ${this.currentLongitude}`) return true

    return false
  }

  connect () {
    this.allowSubmit   = true
    this.afterLocating = null

    this.fetchGeoLocationLater = _.debounce(this.fetchGeoLocation.bind(this), 300)

    if (this.hasTextInputTarget) {
      let locationInput = $(this.textInputTarget)

      if (locationInput.is(`:not(${GEOLOCATOR_LABEL} *)`)) {
        locationInput.wrap(GEOLOCATOR_LABEL_HTML).before(GEOLOCATOR_ICON_HTML)
      }
    }

    if (this.hasTextLabelTarget && this.textInputIsCurrentLocation) {
      this.textLabelTarget.classList.remove('geolocating')
      this.textLabelTarget.classList.add('geolocated')
    }
  }

  geoLocating () {
    this.allowSubmit = false

    if (this.hasTextLabelTarget) {
      this.textLabelTarget.classList.remove('geolocated')
      this.textLabelTarget.classList.add('geolocating')
    }
  }

  geoLocated (lat, lng) {
    this.allowSubmit      = true
    this.currentLatitude  = lat
    this.currentLongitude = lng

    if (this.hasTextLabelTarget) {
      this.textLabelTarget.classList.remove('geolocating')
      this.textLabelTarget.classList.add('geolocated')
    }

    if (_.isFunction(this.afterLocating)) {
      this.afterLocating()
      this.afterLocating = null
    }
  }

  noGeoLocation () {
    this.allowSubmit      = true
    this.currentLatitude  = null
    this.currentLongitude = null

    if (this.hasTextLabelTarget) {
      this.textLabelTarget.classList.remove('geolocating')
      this.textLabelTarget.classList.remove('geolocated')
    }

    if (_.isFunction(this.afterLocating)) {
      this.afterLocating()
      this.afterLocating = null
    }
  }

  fetchedGeoCoordinates (results, status) {
    if (status == google.maps.GeocoderStatus.OK && results[0]) {
      let geoLocation = results[0].geometry.location
      this.geoLocated(geoLocation.lat(), geoLocation.lng())
    } else {
      this.noGeoLocation()
    }
  }

  fetchedCurrentLocation (position) {
    let lat = Math.round(position.coords.latitude  * 1000000) / 1000000
    let lng = Math.round(position.coords.longitude * 1000000) / 1000000

    this.geoLocated(lat, lng)

    this.textValue = this.data.get('current-location-label')
    if (this.hasTextInputTarget) this.textInputTarget.blur()
  }

  failedFetchingCurrentLocation () {
    this.textValue = ''
    if (this.hasTextInputTarget) this.textInputTarget.focus()

    this.noGeoLocation()
  }

  fetchCurrentLocation () {
    this.currentLatitude  = null
    this.currentLongitude = null

    if (!this.supportsGeolocation) return

    this.geoLocating()

    this.geolocation.getCurrentPosition(
      this.fetchedCurrentLocation.bind(this),
      this.failedFetchingCurrentLocation.bind(this)
    )
  }

  fetchGeoLocation () {
    this.currentLatitude  = null
    this.currentLongitude = null

    if (this.textInputIsCurrentLocation) {
      this.fetchCurrentLocation()
      return
    }

    if (!this.supportsGeocoding) return

    if (this.textValue.length) {
      this.geoLocating()

      let query    = `${this.textValue}, ${this.data.get('country')}`
      let geocoder = new this.geocoder()
      geocoder.geocode({ address: query }, this.fetchedGeoCoordinates.bind(this))
    }
  }

  updateLocation (event) {
    this.allowSubmit = false

    if (this.textValue.length) this.fetchGeoLocationLater()
  }

  submitLocation (event) {
    if (!this.textValue.length) return

    if (!this.allowSubmit) {
      event.preventDefault()

      if (this.hasFormTarget) this.afterLocating = () => { this.formTarget.submit() }

      this.fetchGeoLocation()
    }
  }
}
