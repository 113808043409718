import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [
    'dateSelect',
    'timeSelect'
  ]

  static values = {
    pickupMethod:        { type: String, default: 'takeaway'                },
    takeawayLegend:      { type: String, default: 'Requested pickup time'   },
    takeawayDateOptions: { type: String, default: ''                        },
    takeawayTimeOptions: { type: JSON,   default: {}                        },
    deliveryLegend:      { type: String, default: 'Requested delivery time' },
    deliveryDateOptions: { type: String, default: ''                        },
    deliveryTimeOptions: { type: JSON,   default: {}                        }
  }

  get pickupMethod ()        { return this.pickupMethodValue                       }
  get legendLabel ()         { return this[`${this.pickupMethod}LegendValue`]      }
  get dateOptions ()         { return this[`${this.pickupMethod}DateOptionsValue`] }
  get timeOptionsForDates () { return this[`${this.pickupMethod}TimeOptionsValue`] }

  changeAvailableTimes () {
    if (!this.hasDateSelectTarget || !this.hasTimeSelectTarget) return

    let legend = this.element.querySelector('legend')
    if (legend) {
      legend.innerHTML = `<span>${this.legendLabel}</span>`
    }

    let selectedDate = this.dateSelectTarget.value
    let selectedTime = this.timeSelectTarget.value

    let availableDateOptions = this.dateOptions
    this.dateSelectTarget.innerHTML = availableDateOptions
    for (let option of this.dateSelectTarget.options) {
      if (option.value == selectedDate) {
        this.dateSelectTarget.value = selectedDate
        break
      }
    }

    selectedDate = this.dateSelectTarget.value

    let availableTimeOptions = this.timeOptionsForDates[selectedDate]
    this.timeSelectTarget.innerHTML = availableTimeOptions
    for (let option of this.timeSelectTarget.options) {
      if (option.value == selectedTime) {
        this.timeSelectTarget.value = selectedTime
        break
      }
    }
  }

}
