import ApplicationController from './application_controller'
import _ from 'underscore'
import Rails from '@rails/ujs'

export default class extends ApplicationController {

  static targets = [
    'pickupMethodOption',
    'deliveryDetails',
    'shoppingCart',
    'zipcode',
    'street',
    'cityName',
    'shoppingCartToken'
  ]

  static values = {
    pickupMethod:          { type: String, default: 'takeaway' },
    countryCode:           { type: String, default: 'nl'       },
    updateShoppingCartUrl: { type: String, default: ''         }
  }

  get pickupMethod () {
    if (this.pickupMethodOptionTargets.length) {
      let option = this.pickupMethodOptionTargets.find((option) => option.checked)
      return option ? option.value : 'takeaway'
    } else {
      return this.pickupMethodValue
    }
  }

  get shoppingCartToken () {
    if (this.hasShoppingCartTokenTarget) {
      return this.shoppingCartTokenTarget.value
    }
  }

  connect () {
    this.showRelevantDetails      = this.showRelevantDetails.bind(this)
    this.updateAddressInputFields = this.updateAddressInputFields.bind(this)

    this.showRelevantDetails()
    this.updateStreetAndCity()
  }

  showRelevantDetails () {
    if (this.hasDeliveryDetailsTarget) {
      if (this.pickupMethod == 'delivery') {
        this.deliveryDetailsTarget.classList.remove('is-hidden')
      } else {
        this.deliveryDetailsTarget.classList.add('is-hidden')
      }
    }
  }

  updateDateAndTimeSelect () {
    let dateAndTimeSelect = this.element.querySelector('[data-controller*="date-and-time-select"]')

    if (dateAndTimeSelect) {
      dateAndTimeSelect.dataset.dateAndTimeSelectPickupMethodValue = this.pickupMethod
      let dateSelect = dateAndTimeSelect.querySelector('[data-date-and-time-select-target="dateSelect"]')

      if (dateSelect) {
        let event = new Event('change')
        dateSelect.dispatchEvent(event)
      }
    }
  }

  changePickupMethod (event) {
    this.showRelevantDetails()
    this.updateDateAndTimeSelect()

    Rails.ajax({
      type:     'PUT',
      url:      this.updateShoppingCartUrlValue,
      data:     `pickup_method=${this.pickupMethod}&shopping_cart_token=${this.shoppingCartToken}`,
      dataType: 'script'
    })
  }

  updateAddressInputFields ({street, city_name} = response) {
    if(street !== "" && this.streetTarget.value.length == 0) {
      this.streetTarget.value = street
    }

    if(city_name !== "" && this.cityNameTarget.value.length == 0) {
      this.cityNameTarget.value = city_name
    }
  }

  updateStreetAndCity () {
    let zipcode = this.zipcodeTarget.value.replace(/\s+/g, '')

    if (zipcode.length < 4) { return }

    this.fetchStreetAndCity(zipcode, this.countryCodeValue)
  }

  fetchStreetAndCity (zipcode, countryCode) {
    Rails.ajax({
      type:     'GET',
      url:      `/address/${countryCode}/${zipcode}`,
      dataType: 'json',
      success:  this.updateAddressInputFields
    })
  }
}
