import $ from 'jquery'
import _ from 'underscore'
import ApplicationController from '../application_controller'
import Bloodhound from 'corejs-typeahead'

require('corejs-typeahead/dist/typeahead.bundle.js')
require('../../lib/polyfills/starts_with')

export default class extends ApplicationController {

  static targets = ['textInput']

  static values = {
    autocompleteUrl: { type: String, default: '/autocomplete/where' },
    locale:          { type: String, default: 'nl'                  }
  }

  get supportsGeocoding () {
    return !!(navigator && navigator.geolocation)
  }

  disconnect () {
    if (this.input) {
      this.input.typeahead('destroy')
    }
  }

  connect () {
    _.defer(() => {
      if (!this.hasTextInputTarget) return

      const input  = this.input  = $(this.textInputTarget)
      const engine = this.engine = new Bloodhound({
        datumTokenizer(datum) { return Bloodhound.tokenizers.whitespace(datum.value); },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: `${this.autocompleteUrlValue}?query=&locale=${this.localeValue}`,
        remote: {
          wildcard: '%QUERY',
          url:      `${this.autocompleteUrlValue}?query=%QUERY&locale=${this.localeValue}`,
          filter:   this.locationsFromResponse.bind(this)
        }
      })

      engine.initialize()

      input.typeahead({
        hint:      false,
        highlight: true,
        minLength: 0
      }, {
        source:      engine.ttAdapter(),
        displayKey: 'name',
        limit:      7,
        templates: {
          suggestion: this.locationSuggestion
        }
      })
    })
  }

  locationSuggestion (location) {
    if (location.type === 'GeoLocation') {
      return `
        <div data-action="click->forms--geolocate#fetchCurrentLocation" class='tt-suggestion tt-selectable tt-current-location'>
          ${location.name}
        </div>`
    } else {
      return `
        <div class='tt-suggestion tt-selectable tt-${location.type.toLowerCase()}-type'>
          ${location.name}
          <strong class='tt-count'>${location.venues}</strong>
        </div>`
    }
  }

  locationsFromResponse (response) {
    let locations = response.results;

    if (this.supportsGeocoding) {
      if (locations[0] && locations[0].type == 'GeoLocation') {
        return locations
      }

      const textInputValue = this.input.val()
      const currentLocationLabel = this.input.closest('[data-controller*="forms--geolocate"]').data('forms--geolocate-current-location-label');

      if ((textInputValue.length == 0) || currentLocationLabel.toLowerCase().startsWith(textInputValue.toLowerCase())) {
        locations.unshift({
          name: currentLocationLabel,
          type: 'GeoLocation'
        })
      }
    }
    return locations;
  }
}
