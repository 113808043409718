import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['input', 'item']

  filter() {
    if (!this.hasInputTarget || !this.hasItemTarget) return

    let query = this.inputTarget.value.trim()
                    .replace(RegExp(' +', 'g'), ' ')
                    .toLowerCase().split(' ')

    Array.prototype.forEach.call(this.itemTargets, function(item, index, array){
      var itemText = item.textContent.replace(/\s+/g, ' ').toLowerCase()

      if (itemText.indexOf(query) > -1) {
        if (item.classList.contains('is-hidden')) {
          item.classList.remove('is-hidden')
        }
      } else {
        item.classList.add('is-hidden')
      }
    })
  }
}
