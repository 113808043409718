import $ from 'jquery'
import URI from 'urijs'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [ 'input', 'url', 'preview', 'progress', 'destroy' ]

  set progress (progress) {
    $(this.progressTarget).css('width', `${Math.ceil(progress * 100)}%`)
  }

  get files ()           { return this.inputTarget.files                                        }
  get presignedFields () { return Object.entries(JSON.parse(this.data.get('presigned-fields'))) }
  get presignedURL ()    { return this.data.get('presigned-url')                                }

  connect () {
    this.inputTarget.multiple = false
  }

  preview () {
    this.previewTarget.innerHTML = ''

    for (let file of this.files) {
      let reader = new FileReader()

      reader.onload = function(event) {
        this.previewTarget.innerHTML += `<img src="${event.currentTarget.result}" />`
      }.bind(this)

      reader.readAsDataURL(file)
    }
  }

  upload () {
    // Change classes of the widget so we can alter it's UI:
    this.element.classList.remove('is-blank')
    this.element.classList.add('is-uploaded')

    this.preview()

    for (let file of this.files) {
      let formData = new FormData()
      for (let data of this.presignedFields) formData.append(...data)
      formData.append('file', file)

      $.ajax(this.presignedURL, {
        method:      'POST',
        dataType:    'xml',
        data:        formData,
        contentType: false,
        processData: false,
        xhr:         this.createXHR.bind(this),
        complete:    this.uploadComplete.bind(this),
        success:     this.uploadSuccess.bind(this),
        error:       this.uploadError.bind(this)
      })
    }

    this.element.classList.add('is-uploading')
  }


  createXHR () {
    let xhr = $.ajaxSettings.xhr()

    xhr.upload.addEventListener('progress', this.uploadProgress.bind(this), false)
    xhr.addEventListener('progress', this.uploadProgress.bind(this), false)

    return xhr
  }

  uploadProgress (event) {
    if (event.lengthComputable) {
      this.progress = event.loaded / event.total
    } else {
      this.progress = 0.0
    }
  }

  uploadComplete (response) {
    let locationNode = response.responseXML.getElementsByTagName('Location')[0]
    let location     = URI(locationNode.childNodes[0].nodeValue)

    // The path of the URL that S3 responds with has been encoded. The
    // backend does not expect an encoded url.
    location.path(decodeURIComponent(location.path()))

    this.urlTarget.value   = location
    this.inputTarget.value = null
  }

  uploadSuccess (response) {
    this.element.classList.remove('is-uploading')
  }

  uploadError (response) {
    this.element.classList.remove('is-uploading')
    this.element.classList.add('upload-error')
  }

  delete () {
    if (this.hasUrlTarget)     this.urlTarget.value   = null
    if (this.hasInputTarget)   this.inputTarget.value = null
    if (this.hasPreviewTarget) this.previewTarget.innerHTML = ''

    if (this.hasDestroyTarget) {
      if (this.destroyTarget.type == 'checkbox') {
        this.destroyTarget.checked = true
      } else {
        this.destroyTarget.value = '1'
      }
    }

    this.element.classList.add('is-destroyed')
  }
}
