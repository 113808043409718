import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  expand () {
    this.element.classList.add('is-toggled')
  }

  close () {
    let toggle = document.querySelector('.toggle-search')
    if (toggle) toggle.classList.remove('is-toggled')
  }
}
