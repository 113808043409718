import $ from 'jquery'
import _ from 'underscore'
import ApplicationController from './application_controller'

export default class extends ApplicationController {


  get delay () {
    return parseInt(this.data.get('delay'))
  }

  connect() {
    if (this.delay > 0) {
      _.delay(() => this.load(), this.delay * 1000)
    } else {
      this.load()
    }
  }

  load () {
    let reloadURL = this.data.get('url')
    if (!reloadURL.length) return

    let request  = $.ajax(reloadURL, {
      method:   'GET',
      dataType: 'html'
    }).done(
      (response) => {
        $(this.element).replaceWith(response)
      }
    )
  }
}
