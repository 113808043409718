import * as d3 from 'd3'
import _ from 'underscore'
import ApplicationController from '../application_controller'

const MARGIN_LABELS = 6

export default class extends ApplicationController {

  static targets = ['data', 'output']

  get output () {
    return d3.select(this.outputTarget)
  }

  get inputData () {
    let rows = d3.select(this.dataTarget).selectAll('tr').nodes()
    return rows.map(
      (row) => {
        row = d3.select(row)

        let label       = row.select('th').text()
        let occurrences = row.select('td').text()

        return {
          label:       parseInt(label),
          occurrences: parseInt(occurrences)
        }
      }
    )
  }

  get labels() {
    return this.inputData.map(datum => datum.label)
  }

  get occurrences() {
    return this.inputData.map(datum => datum.occurrences)
  }

  get svg() {
    let existing = this.output.select('svg')
    if (existing.size()) {
      return existing
    } else {
      return this.output.append('svg')
    }
  }

  get group() {
    let existing = this.svg.select('g')
    if (existing.size()) {
      return existing
    } else {
      return this.svg.append('g')
    }
  }

  get yAxisGroup() {
    let existing = this.group.select('g.y-axis')
    if (existing.size()) {
      return existing
    } else {
      return this.group.append('g')
                 .attr('class', 'y-axis')
    }
  }

  get rectangle() {
    return this.svg.node().getBoundingClientRect()
  }

  get width() {
    return this.rectangle.width
  }

  get height() {
    return this.rectangle.height
  }

  connect() {
    this.resize = this.resize.bind(this)
    window.addEventListener('resize', this.resize)

    _.defer(() => this.draw())
  }

  disconnect() {
    window.removeEventListener('resize', this.resize)
    _.defer(() => this.undraw())
  }

  resize(event) {
    this.undraw()
    this.draw()
  }

  prepareScales() {
    this.xScale = d3.scaleLinear()
    this.yScale = d3.scaleBand()

    this.xScale.domain([0, d3.max(this.occurrences)])

    this.yScale.domain(d3.range(1, 11))
    this.yScale.paddingInner(0.1)
  }

  undraw() {
    if (this.group.size()) {
      this.group.selectAll('.y-axis, .bar, .bar-background, .occurrences-label').remove()
    }
  }

  draw() {
    this.prepareScales()

    this.yScale.rangeRound([this.height, 0])

    // Add labels on y-axis:
    let labels = this.yAxisGroup.call(
      d3.axisLeft(this.yScale)
        .ticks(this.yScale.domain())
        .tickSize(0)
    ).selectAll('text')
     .attr('x', 0)
     .nodes()

    let labelWidth = Math.ceil(d3.max(labels, l => l.getComputedTextLength()))
    let barLeft    = labelWidth + MARGIN_LABELS

    this.yAxisGroup.attr('transform', `translate(${labelWidth}, 0)`)

    this.xScale.rangeRound([0, this.width - barLeft])

    // Add purple bars:
    this.group
      .selectAll('.bar')
      .data(this.inputData)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('height', this.yScale.bandwidth())
      .attr('width',  datum => Math.max(this.xScale(datum.occurrences), 0))
      .attr('y',      datum => this.yScale(datum.label))
      .attr('x',      barLeft)

    // Add background bars:
    this.group
      .selectAll('.bar-background')
      .data(this.inputData)
      .enter()
      .append('rect')
      .attr('class', 'bar-background')
      .attr('height', this.yScale.bandwidth())
      .attr('width',  datum => Math.max(this.width - barLeft - this.xScale(datum.occurrences), 0))
      .attr('x',      datum => barLeft + this.xScale(datum.occurrences))
      .attr('y',      datum => this.yScale(datum.label))

    // Add labels inside/outside purple bars:
    this.group
      .selectAll('.occurrences-label')
      .data(this.inputData)
      .enter()
      .append('text')
      .text(datum => (datum.occurrences > 0 && datum.occurrences) || 0)
      .attr('dy', '0.32em')
      .attr('class', 'occurrences-label')
      .attr('class', (datum, i, nodes) => this.barLabelClass(datum, nodes[i]))
      .attr('x',     (datum, i, nodes) => this.barLabelOffset(datum, nodes[i]) + barLeft)
      .attr('y',     (datum, i, nodes) => this.yScaleCenter(datum.label))
  }

  barLabelOffset(datum, node) {
    let textWidth = node.getComputedTextLength()
    let barWidth  = this.xScale(datum.occurrences)

    if (this.textNodeFitsInBar(textWidth, barWidth))
      return (barWidth - textWidth - MARGIN_LABELS)
    else
      return barWidth + MARGIN_LABELS
  }

  barLabelClass(datum, node) {
    let textWidth    = node.getComputedTextLength()
    let barWidth     = this.xScale(datum.occurrences)
    let currentClass = d3.select(node).attr('class')

    if (this.textNodeFitsInBar(textWidth, barWidth))
      return `in-bar ${currentClass}`
    else
      return `after-bar ${currentClass}`
  }

  textNodeFitsInBar(textWidth, barWidth) {
    return (textWidth + 2 * MARGIN_LABELS) <= barWidth
  }

  yScaleCenter(value) {
    return this.yScale(value) + this.yScale.bandwidth() / 2
  }
}
