import _ from 'underscore'
import { currentUser } from '../../models/current_user'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [ 'method', 'button' ]

  set following (value) {
    this.data.set('following', value)
    this.updateButtonLabel()
  }

  get following () {
    return this.data.get('following') == 'true'
  }

  get followedUserIds () {
    if (this.currentUser && this.currentUser.followed_users) {
      return _.map(this.currentUser.followed_users, id => parseInt(id))
    } else {
      return []
    }
  }

  connect () {
    this.userId = parseInt(this.data.get('user-id'))

    currentUser.fetch().done(() => {
      this.currentUser = currentUser.user
      this.following   = _.contains(this.followedUserIds, this.userId)
    }).fail(() => {
      this.following = false
    })
  }

  updateButtonLabel() {
    if (this.following) {
      this.buttonTarget.value = this.data.get('unfriend-label')
      this.methodTarget.value = 'delete'
    } else {
      this.buttonTarget.value = this.data.get('befriend-label')
      this.methodTarget.value = 'post'
    }
  }
}
