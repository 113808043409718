import $ from "jquery"
import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  get promotion         () { return $(this.element)                     }
  get placeholder       () { return this.promotion.find('.placeholder') }
  get form              () { return this.promotion.find('form')         }
  get segmentSelect     () { return this.form.find('li.segment select') }
  get subscribedMessage () { return this.promotion.find('.subscribed')  }

  connect() {
    if (this.form.length >= 1) {
      this.fetchSegments()
    }
  }

  fetchSegments() {
    var segmentsURL = this.segmentSelect.data('url')
    $.ajax(segmentsURL).done(data => { this.appendSegments(data) })
  }

  appendSegments(data) {
    this.segmentSelect.empty()

    var options = data.results.map(segment => `<option value="${segment.id}">${segment.name}</option>`)
    options = ['<option value=""></option>', ...options]

    options.forEach(option => { this.segmentSelect.append(option) })

    this.placeholder.addClass('is-hidden')
    this.form.removeClass('is-hidden')
  }

  subscribe(event) {
    event.preventDefault()

    var formData = new FormData(this.form.get(0))
    var formURL  = this.form.attr('action')

    var request  = $.ajax(formURL, {
      method:      'POST',
      dataType:    'json',
      jsonp:       false,
      contentType: false,
      processData: false,
      data:        formData
    }).done(
      this.showSubscribedMessage.bind(this)
    ).fail(
      this.showErrorMessages.bind(this)
    )
  }

  showSubscribedMessage(xhr) {
    this.clearFormErrors()

    this.form.addClass('is-hidden')
    this.subscribedMessage.removeClass('is-hidden')
  }

  showErrorMessages(xhr) {
    this.clearFormErrors()

    var response = xhr.responseJSON
    $.each(response.errors, (field, errors) => {
      var inputWrapper = this.form.find(`[data-field="${field}"]`)
      inputWrapper.append(`<p class="inline-errors">${errors.join(', ')}</p>`)
    })
  }

  clearFormErrors() {
    this.form.find('.inline-errors').remove()
  }
}
