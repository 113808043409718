import _ from 'underscore'
import ApplicationController from './application_controller'

function toMercator (y) {
  return Math.log(
    Math.tan(
      (Math.PI / 4) +
      ((y * (Math.PI / 180)) / 2)
    )
  ) * (180 / Math.PI)
}

export default class extends ApplicationController {
  static targets = [ 'canvas', 'link', 'region' ]

  get svg ()      { return this._svg  }
  set svg (value) { this._svg = value }

  connect () {
    _.defer(() => {
      if (this.hasCanvasTarget) {
        this.initializeCanvas()
      }
    })
  }

  disconnect () {
    if (this.hasCanvasTarget && this.svg) {
      this.canvasTarget.removeChild(this.svg)
    }
  }

  enterLink (event) {
    let link   = event.currentTarget
    let region = this.regionTargets.find((region) => region.getAttribute('data-name') == link.getAttribute('data-name'))
    if (region) region.classList.add('hovered')
  }

  exitLink (event) {
    let link   = event.currentTarget
    let region = this.regionTargets.find((region) => region.getAttribute('data-name') == link.getAttribute('data-name'))
    if (region) region.classList.remove('hovered')
  }

  enterRegion (event) {
    let region = event.currentTarget
    let link   = this.linkTargets.find((link) => link.getAttribute('data-name') == region.getAttribute('data-name'))
    if (link) link.classList.add('hover-on-map')
  }

  leaveRegion (event) {
    let region = event.currentTarget
    let link   = this.linkTargets.find((link) => link.getAttribute('data-name') == region.getAttribute('data-name'))
    if (link) link.classList.remove('hover-on-map')
  }

  openRegion (event) {
    let target = event.currentTarget
    document.location = target.getAttribute('href')
    event.preventDefault()
  }

  initializeCanvas () {
    var minX = 180
    var minY = 90
    var maxX = -180
    var maxY = -90

    for (let link of this.linkTargets) {
      let nodes = link.getAttribute('data-area').replace('&', '|&|').split(/[|+]/)
      for (let node of nodes) {
        if (node != '&') {
          let coordinates = node.split(',')
          let x = parseFloat(coordinates[0])
          let y = parseFloat(coordinates[1])

          if (minX > x) minX = x
          if (maxX < x) maxX = x
          if (minY > y) minY = y
          if (maxY < y) maxY = y
        }
      }
    }

    maxY = toMercator(maxY)
    minY = toMercator(minY)

    var xRange = maxX - minX
    var yRange = maxY - minY

    var xRatio = xRange < yRange ? xRange / yRange : 1
    var yRatio = yRange < xRange ? yRange / xRange : 1

    var xOffset = xRange < yRange ? (100 - (100 * xRatio)) / 2 : 0
    var yOffset = yRange < xRange ? (100 - (100 * yRatio)) / 2 : 0

    let toRelativeCoords = function(x, y) {
      let relX = (maxX - x) / xRange
      let relY = (maxY - toMercator(y)) / yRange
      return [
        100 - xOffset - (relX * 100 * xRatio),
        relY * 100 * yRatio + yOffset
      ]
    }

    this.svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    this.svg.setAttribute('version', '1.1')
    this.svg.setAttribute('viewBox', '0 0 100 100')

    for (let link of this.linkTargets) {
      let g = document.createElementNS('http://www.w3.org/2000/svg', 'g')
      g.setAttribute('id', `map-region-${link.getAttribute('data-name')}`)
      g.setAttribute(`data-${this.identifier}-target`, 'region')
      g.setAttribute('data-name', link.getAttribute('data-name'))

      let regionParts = link.getAttribute('data-area').split('+')
      for (let regionPart of regionParts) {
        let path = document.createElementNS('http://www.w3.org/2000/svg', 'path')

        let d = ''
        let nodes = regionPart.replace('&', '|&|').split('|')
        let firstNodeCoords = nodes[0].split(',')
        let relFirstNodeCoords = toRelativeCoords(firstNodeCoords[0], firstNodeCoords[1])
        d += `M${relFirstNodeCoords[0]},${relFirstNodeCoords[1]}`

        for (let node of nodes.slice(1)) {
          if (node == '&') {
            d += 'ZM'
          } else {
            let coords = node.split(',')
            let relCoords = toRelativeCoords(coords[0], coords[1])
            d += `L${relCoords[0]},${relCoords[1]}`
          }
        }

        d += 'Z'
        d = d.replace('ZML', 'ZM')

        path.setAttribute('d', d)
        path.setAttribute('fill-rule', 'evenodd')
        path.setAttribute('stroke-width', '0.3')
        path.setAttribute('class', `${path.getAttribute('class')} color-${link.getAttribute('data-color-code')}`)

        if (link.hasAttribute('href')) {
          g.setAttribute('href', link.getAttribute('href'))
          g.setAttribute('data-action', 'click->region-map#openRegion mouseover->region-map#enterRegion mouseout->region-map#leaveRegion')
        }

        g.appendChild(path)
      }

      this.svg.appendChild(g)
    }

    this.canvasTarget.appendChild(this.svg)
  }
}
