import _ from 'underscore'
import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [ 'tab', 'pane' ]

  connect() {
    this.showInitial()
  }

  hideAll() {
    this.tabTargets.forEach((tab)   => tab.classList.remove('selected'))
    this.paneTargets.forEach((pane) => pane.classList.add('is-hidden'))
  }

  showInitial() {
    if (this.paneTargets.length) {
      let targetFromURL = location.hash.replace(/^(.+)?#/, '')
      let firstTarget   = this.paneTargets[0].getAttribute('id')

      let target = this.findPaneByTarget(targetFromURL) ? targetFromURL : firstTarget
      this.showPane(target, false)
    }
  }

  change (event) {
    let tab    = event.currentTarget
    let target = tab.getAttribute('href').replace(/^(.+)?#/, '')

    this.showPane(target)
    event.preventDefault()
  }

  findTabByTarget (target) {
    return this.tabTargets.find((el) => {
      let anchors = [el].concat(Array.from(el.getElementsByTagName('a')))
      return anchors.find((a) => a.getAttribute('href') == `#${target}`)
    })
  }

  findPaneByTarget(target) {
    return this.paneTargets.find((el) => el.getAttribute('id')   == target)
  }

  showPane (target, pushState = true) {
    let pane = this.findPaneByTarget(target)
    let tab  = this.findTabByTarget(target)

    // Hide/unselect all tabs and panes:
    this.hideAll()

    if (tab) {
      // Highlight current tab:
      tab.classList.add('selected')
    }

    if (pane) {
      // Show current pane:
      pane.classList.remove('is-hidden')

      if (pushState) {
        // Update URL in address bar:
        if (history.pushState)
          history.pushState(null, null, `#${target}`)
        else
          location.hash = `#${target}`
      }
    }
  }
}
