import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['titleInput', 'destroyInput', 'addDishLink']

  get categoryList () {
    return this.findController('menu-editor--category-list')
  }

  get dishes () {
    return this.findChildControllers(
      'menu-editor--dish'
    )
  }

  get anchor () {
    return this.element.id
  }

  get title () {
    let value = _.result(this.titleInputTarget, 'value', '').trim()
    if (value.length < 1) value = '...'
    return value
  }

  get destroyed () {
    return this.hasDestroyInputTarget && this.destroyInputTarget.checked
  }

  get swapping () {
    return this.element.classList.contains('slave-swapper') ||
           this.element.classList.contains('master-swapper')
  }

  connect () {
    this.resetCategoryList()
    this.forceUniqueAnchor()
  }

  forceUniqueAnchor () {
    if (document.getElementById(this.anchor) != this.element) {
      this.element.id = `category_${this.generateUniqueId()}`
    }
  }

  resetCategoryList () {
    if (this.categoryList) {
      this.categoryList.resetCategories()
    }
  }

  changeTitle (event) {
    this.resetCategoryList()
  }

  destroy (event) {
    this.resetCategoryList()
  }

  addDish (dish) {
    if (this.hasAddDishLinkTarget) {
      let dishIndex = this.dishes.length

      window.doNotFocus = true
      this.addDishLinkTarget.onclick()

      _.defer(() => {
        let newDish = this.dishes[dishIndex]

        newDish.title         = dish.title
        newDish.description   = dish.description
        newDish.price         = dish.price
        newDish.visible       = dish.visible
        newDish.invisibleTill = dish.invisibleTill

        dish.optionGroups.forEach(
          (group) => _.defer(() => newDish.addOptionGroup(group))
        )

        dish.options.forEach(
          (option) => _.defer(() => newDish.addOption(option))
        )

        _.delay(() => {
          window.doNotFocus = false
          newDish.focus()
        }, 100)
      })
    }
  }
}
