import * as d3 from 'd3'
import I18n from 'i18n-js'
import _ from 'underscore'
import ApplicationController from '../application_controller'

const MARGIN_LABELS = 6
const MARGIN_TOP    = 6 * 2
const MARGIN_BOTTOM = 6 * 4

export default class extends ApplicationController {

  static targets = ['data', 'output']

  get output () {
    return d3.select(this.outputTarget)
  }

  get inputData () {
    let rows = d3.select(this.dataTarget).selectAll('tbody tr')

    return rows.nodes().map(
      (row) => {
        row = d3.select(row)

        let date = row.select('th').attr('title')

        let pageviews     = row.select('td.pageviews').text()
        let clickthroughs = row.select('td.clickthroughs').text()

        return {
          snapshot:      new Date(1000 * parseInt(date)),
          pageviews:     parseInt(pageviews),
          clickthroughs: parseInt(clickthroughs)
        }
      }
    )
  }

  get dates()          { return this.inputData.map(datum => datum.date)          }
  get pageviews()      { return this.inputData.map(datum => datum.pageviews)     }
  get clickthroughs () { return this.inputData.map(datum => datum.clickthroughs) }

  get minValue () { return Math.min(...this.pageviews, ...this.clickthroughs,  0) }
  get maxValue () { return Math.max(...this.pageviews, ...this.clickthroughs, 10) }

  get firstLabel () { return I18n.l('date.formats.long', this.inputData[0].snapshot) }
  get lastLabel  () { return I18n.t('charts.analytics.yesterday')                    }

  get svg() {
    let existing = this.output.select('svg')
    if (existing.size()) {
      return existing
    } else {
      return this.output.append('svg')
    }
  }

  get group() {
    let existing = this.svg.select('g')
    if (existing.size()) {
      return existing
    } else {
      return this.svg.append('g')
    }
  }

  get rectangle() {
    return this.svg.node().getBoundingClientRect()
  }

  get width() {
    return this.rectangle.width
  }

  get height() {
    return this.rectangle.height
  }

  connect() {
    this.resize = this.resize.bind(this)
    window.addEventListener('resize', this.resize)

    _.defer(() => this.draw())
  }

  disconnect() {
    window.removeEventListener('resize', this.resize)
    _.defer(() => this.undraw())
  }

  resize(event) {
    this.undraw()
    this.draw()
  }

  prepareScales() {
    this.yScale = d3.scaleLinear()
    this.yScale.domain([this.minValue, this.maxValue])

    this.xScale = d3.scaleTime()
    this.xScale.domain(d3.extent(this.inputData, (datum) => datum.snapshot))
  }

  undraw() {
    if (this.group.size()) {
      this.group.selectAll('.pageviews-line, .clickthroughs-line, .pageviews-dot, .clickthroughs-dot, .x-axis, .y-axis').remove()
    }
  }

  drawDottedLine (subject) {
    let line =
      d3.line()
        .y((datum) => this.yScale(datum[subject]))
        .x((datum) => this.xScale(datum.snapshot))

    this.group
        .append('path')
        .datum(this.inputData)
        .attr('class', `${subject}-line`)
        .attr('transform', `translate(0, ${MARGIN_TOP})`)
        .attr('d', line)

    this.group
        .selectAll('dot')
        .data(this.inputData)
        .enter().append('circle')
        .attr('r', 3)
        .attr('class', `${subject}-dot`)
        .attr('cy', (datum) => this.yScale(datum[subject]) + MARGIN_TOP)
        .attr('cx', (datum) => this.xScale(datum.snapshot))
        .on('mouseover', (d) => {
          this.showTooltip(d3.event.pageX, d3.event.pageY, `
            <strong>${I18n.l('date.formats.long', d.snapshot)}</strong><br/>
            ${subject}: ${d[subject]}
          `, `${subject}-tooltip`)
        }).on('mouseout', (d) => this.hideTooltip())
  }

  drawAxes () {
    this.yScale.rangeRound([this.height - MARGIN_TOP - MARGIN_BOTTOM, 0])

    let yAxis = d3.axisLeft(this.yScale)

    let yAxisGroup =
      this.group
          .append('g')
          .attr('class', 'y-axis').call(yAxis)

    yAxisGroup.selectAll('text')
              .attr('x', 0)
    yAxisGroup.selectAll('.tick text')
              .attr('transform', `translate(-${MARGIN_LABELS}, 0)`)

    let labels     = yAxisGroup.selectAll('text').nodes()
    let labelWidth = Math.ceil(d3.max(labels, (l) => l.getComputedTextLength()))
    let pathLeft   = labelWidth + MARGIN_LABELS

    this.xScale.rangeRound([pathLeft, this.width])

    yAxisGroup.attr('transform', `translate(${pathLeft}, ${MARGIN_TOP})`)
    yAxisGroup.selectAll('line').attr('x2', this.width - pathLeft)

    let xAxisGroup = this.group.append('g').attr('class', 'x-axis')

    let firstLabel =
      xAxisGroup.append('text')
                .attr('class', 'then')
                .attr('text-anchor', 'begin')
                .attr('x', 0)
                .text(this.firstLabel)

    let lastLabel =
      xAxisGroup.append('text')
                .attr('class', 'yesterday')
                .attr('text-anchor', 'end')
                .attr('x', this.width - pathLeft)
                .text(this.lastLabel)

    let labelHeight = d3.max([firstLabel, lastLabel], (l) => l.node().getBBox().height)
    let labelTop    = this.height - (MARGIN_BOTTOM - labelHeight) / 2

    xAxisGroup.attr('transform', `translate(${pathLeft}, ${labelTop})`)
  }

  draw() {
    this.prepareScales()

    this.drawAxes()

    this.drawDottedLine('pageviews')
    this.drawDottedLine('clickthroughs')
  }

  showTooltip (left, top, value, htmlClass) {
    if (!this.tooltip) {
      this.tooltip =
        d3.select('body')
          .append('div')
          .style('display', 'none')
    }

    this.tooltip
        .attr('class', `analytics-tooltip ${htmlClass}`)
        .style('display', 'block')
    this.tooltip
        .html(value)
        .style('left', `${left}px`)
        .style('top',  `${top}px`)
  }

  hideTooltip () {
    if (this.tooltip) {
      this.tooltip.style('display', 'none')
    }
  }
}
