import L from 'leaflet'
import _ from 'underscore'

export default class {
  get defaultOptions () {
    return {
      interactive: true,
      riseOnHover: true
    }
  }
  
  
  get hasOpenPopup () { return this.leaflet.isPopupOpen() }
  
  get latLng ()      { return this.leaflet.getLatLng() }
  set latLng (value) { this.leaflet.setLatLng(value)   }
  
  constructor (latLng, options = {}) {
    this.options = _.defaults(options, this.defaultOptions)
    this.leaflet = L.marker(latLng, this.options)
  }
  
  addTo (map) {
    this.leaflet.addTo(map.leaflet)
  }
  
  removeFrom (map) {
    map.leaflet.removeLayer(this.leaflet)
  }
}