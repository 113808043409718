import $ from 'jquery'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  delete (event) {
    let checkbox = $(event.currentTarget)
    let wrapper  = checkbox.closest('.input.boolean')
    let message  = this.data.get('confirm')

    if ((message != null ? message.length : undefined) > 0) {
      if (checkbox.is(':checked') && confirm(message)) {
        checkbox = $(checkbox)
        let wrapper  = checkbox.closest('.input.boolean, .input.boolean_cell')
        let parent   = wrapper.closest('[data-controller*="menu-editor--deletable"]')

        parent.animate({height: 'toggle', opacity: 'toggle'}, 250,
          function() {
            parent.hide()
            $(document).trigger('deleted-menu-item')
          }
        )
      } else {
        checkbox.attr('checked', false)
        return event.preventDefault()
      }
    } else {
      if (checkbox.is(':checked')) {
        checkbox = $(checkbox)
        let wrapper  = checkbox.closest('.input.boolean, .input.boolean_cell')
        let parent   = wrapper.closest('[data-controller*="menu-editor--deletable"]')

        parent.animate({height: 'toggle', opacity: 'toggle'}, 250,
          function() {
            parent.hide()
            $(document).trigger('deleted-menu-item')
          }
        )
      }
    }
  }
}
