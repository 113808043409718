import $ from 'jquery'

$(document).on('turbolinks:load', function() {
  if ($('.reward-video-player').length) {
    var player;
    var dataVideoId = $('.reviews-done .reward-video-player').data('video-id');
    var startTime   = $('.reviews-done .reward-video-player').data('video-start-time');
    var videoMessage = $('.reviews-done .reward-video-player').data('video-message');

    var startPlayer = function() {
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";

      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    startPlayer()

    window.onYouTubeIframeAPIReady = function() {
      player = new YT.Player('reward_video', {
        height:     '390',
        width:      '600',
        videoId:    dataVideoId,
        playerVars: {
            start:     startTime,
            rel:       0,
            disablekb: 1,
            controls:  0,
            fs:        0
          }
      });
    }

    $(document).on('click', '.reviews-done', function(event) {
      if (player) {
        player.playVideo()

        $('.reviews-done .reward-video-image').hide()
        $('.reviews-done .reward-video-player').show()
        $('.empty.video').replaceWith("<p class=\"empty video\">" + videoMessage + "</p>")
      }
    })
  }
})
