import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['descriptionInput', 'priceInput', 'destroyInput', 'addOptionLink']

  get optionGroup () {
    return this.findParentController('menu-editor--option-group')
  }

  get description () {
    return this.hasDescriptionInputTarget && this.descriptionInputTarget.value
  }
  set description (newDescription) {
    if (this.hasDescriptionInputTarget) {
      this.descriptionInputTarget.value = newDescription
    }
  }

  get price () {
    return this.hasPriceInputTarget && this.priceInputTarget.value
  }
  set price (newPrice) {
    if (this.hasPriceInputTarget) {
      this.priceInputTarget.value = newPrice
    }
  }

  get destroyed () {
    return this.hasDestroyInputTarget && this.destroyInputTarget.checked
  }
  set destroyed (trueOrFalse) {
    if (this.hasDestroyInputTarget) {
      this.destroyInputTarget.checked = trueOrFalse
    }
  }

  get asJSON () {
    return {
      description: this.description,
      price:       this.price
    }
  }
}
