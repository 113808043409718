import _ from 'underscore'
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['item']

  connect () {
    _.defer(() => {
      this.sortable = Sortable.create(this.element, {
        draggable: `[data-${this.identifier}-target*="item"]`,
        onEnd:     this.updateSortable.bind(this)
      })
    })
  }

  updateSortable (event) {
    const url = this.data.get('url')
    if (url && url.length) {
      this.sortable.option('disabled', true)

      let request = new XMLHttpRequest()
      request.onreadystatechange = function() {
        if (request.readyState != XMLHttpRequest.DONE) return
        this.sortable.option('disabled', false)
      }.bind(this)

      let method     = 'PUT'
      let parameters = { _method: method }
      parameters[this.data.get('key')] = this.itemTargets.map((el) => el.dataset.sortableListValue)

      request.open(method, url)
      request.setRequestHeader('Content-Type', 'application/json')
      request.setRequestHeader('X-CSRF-Token', Rails.csrfToken())
      request.setRequestHeader('Accept', 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript')
      request.send(JSON.stringify(parameters))
    }
  }

}
