import ApplicationController from '../application_controller'
import $ from 'jquery'
import _ from 'underscore'

require('../../lib/jquery.pricing-select')

export default class extends ApplicationController {
  connect () {
    _.defer(() => $(this.element).pricingSelect())
  }
}
