import L from 'leaflet'
import $ from 'jquery'
import _ from 'underscore'
import Marker from 'lib/maps/marker'
import Dimensions from 'lib/maps/dimensions'

const LABEL_OFFSET_STEP = 12
const LABEL_MARGIN      = 3

const DIRECTIONS = ['top', 'right', 'bottom', 'left']
const OPPOSITES  = {
  left:   'right',
  right:  'left',
  top:    'bottom',
  bottom: 'top'
}

const Z_INDEX_INACTIVE = 0
const Z_INDEX_ACTIVE   = 1000
const Z_INDEX_HOVER    = 1100

export default class extends Marker {
  get icon () {
    if (this.leaflet) {
      return $(this.leaflet._icon)
    } else {
      return $([])
    }
  }
  get iconDimensions () {
    return this.getDimensions(this.icon)
  }
  
  get label () {
    return this.labelWrapper.find('span')
  }
  get labelDimensions () {
    return this.getDimensions(this.label)
  }
  get labelAlignment () {
    let className = this.icon.attr('class')
    
    if (/right/.test(className))  return 'right'
    if (/bottom/.test(className)) return 'bottom'
    if (/left/.test(className))   return 'left'
    
    return 'top'
  }
  
  get labelWrapper () {
    return this.icon.find('.marker-content')
  }
  get labelWrapperDimensions () {
    return this.getDimensions(this.wrapper)
  }
  
  prepareClusterMarker (marker) {
    _.extend(marker.options, this.options)
    marker.setIcon(this.options.icon)
  }
  
  activate () {
    this.leaflet.setZIndexOffset(Z_INDEX_ACTIVE)
    this.active = true
    
    this.updateIcon()
  }
  
  deactivate () {
    this.leaflet.setZIndexOffset(Z_INDEX_INACTIVE)
    this.active = false
    
    this.updateIcon()
  }
  
  
  highlight () {
    this.leaflet.setZIndexOffset(Z_INDEX_HOVER)
    this.icon.addClass('hover')
  }
  
  unhighlight () {
    this.leaflet.setZIndexOffset(Z_INDEX_ACTIVE)
    this.icon.removeClass('hover')
  }
  
  updateIcon () {
    this.icon.removeClass('active inactive')
    
    if (this.active) {
      this.icon.addClass('active')
    } else {
      this.icon.addClass('inactive')
    }
  }
  
  getDimensions (element) {
    let offset = element.offset()
    let width  = element.outerWidth()
    let height = element.outerHeight()
    
    return new Dimensions(offset.left, offset.top, width, height)
  }
  
  setLabelStyle () {
    let wrapper  = this.labelWrapper
    let width    = this.label.outerWidth()
    let minWidth = parseInt(wrapper.css('min-width'))
    
    wrapper.addClass(`label-${Math.ceil(width / minWidth)}-columns`)
    
    let height    = this.label.outerHeight()
    let minHeight = parseInt(wrapper.css('min-height'))
    
    wrapper.addClass(`label-${Math.ceil(height / minHeight)}-rows`)
  }
  
  setLabelPosition (direction, offset) {
    let wrapper = this.labelWrapper
    let icon    = this.icon
    
    icon.removeClass('top-aligned right-aligned bottom-aligned left-aligned')
    icon.addClass(`${direction}-aligned`)
    
    let opposite = OPPOSITES[direction]
    DIRECTIONS.forEach(
      (dir) => dir != opposite ? wrapper.css(dir, '') : null
    )
    
    wrapper.css(opposite, parseInt(wrapper.css(opposite)) + offset)
  }
  
  alignLabel (direction, offset) {
    let wrapper = this.labelWrapper
    let icon    = this.icon
    
    icon.removeClass('top-aligned right-aligned bottom-aligned left-aligned')
    icon.addClass(`${direction}-aligned`)
    
    let opposite = OPPOSITES[direction]
    DIRECTIONS.forEach(
      (dir) => dir != opposite ? wrapper.css(dir, '') : null
    )
    
    wrapper.css(opposite, parseInt(wrapper.css(opposite)) + offset)
  }
  
  drawLineFromMarkerToLabel () {
    if (!this.active) return
    
    let iconDimensions  = this.iconDimensions
    let labelDimensions = this.labelDimensions
    
    let x1 = iconDimensions.center[0], x2 = null
    let y1 = iconDimensions.center[1], y2 = null
    
    switch (this.labelAlignment) {
      case 'top':
        x2 = x1
        y2 = labelDimensions.bottom
        break
      
      case 'bottom':
        x2 = x1
        y2 = labelDimensions.top
        break
      
      case 'right':
        x2 = labelDimensions.left
        y2 = y1
        break
      
      case 'left':
        x2 = labelDimensions.right
        y2 = y1
        break
    }
    
    let length = Math.sqrt((x1 - x2)**2 + (y1 - y2)**2)
    let angle  = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI
    
    let wrapper = this.labelWrapper
    let line    = wrapper.find('.line')
    if (!line.length) {
      line = wrapper.append('<div class="line"></div>').find('.line')
    }
    
    let left = Math.round(Math.min(x1, x2))
    let top  = Math.round(Math.min(y1, y2))
    
    line.css({ 
      position:  'absolute',
      transform: `rotate(${angle}deg)`,
      width:     `${length}px`
    }).offset({
      top:  top,
      left: left
    })
  }
  
  repositionLabel (previousMarkers, nextMarkers) {
    if (!this.active) return
    
    let wrapper = this.labelWrapper
    
    wrapper.show()
    
    // hide the line:
    this.labelWrapper.find('.line').remove()
    
    /*
     * Try to position the label in an outward spiraling way. It starts at the
     * center top position and then rotates by 45 degrees clockwise. If no
     * position is found, try again but with a larger distance between the label
     * and the marker.
     */
    let offsets = Array.from(Array(LABEL_OFFSET_STEP * 4).keys())
    offsets.some((offset) => {
      DIRECTIONS.some((direction) => {
        this.setLabelPosition(direction, offset)
        return !this.hasOverlappingMarkers(previousMarkers, nextMarkers)
      })
      
      return !this.hasOverlappingMarkers(previousMarkers, nextMarkers)
    })
    
    if (this.hasOverlappingMarkers(previousMarkers, nextMarkers)) {
      // No good position for the label was found, so hide it.
      wrapper.hide()
    } else {
      // Redraw the line:
      this.drawLineFromMarkerToLabel()
    }
  }
  
  hasOverlappingMarkers (previous, next) {
    return (
      previous.some((marker) => this.labelOverlaps(marker, true))  ||
      next.some(    (marker) => this.labelOverlaps(marker, false))
    )
  }
  
  labelOverlaps (marker, checkLabel) {
    let dim        = this.labelDimensions.padded(LABEL_MARGIN)
    let overlaps   = dim.overlaps(marker.iconDimensions)
    
    return overlaps || (
      checkLabel && dim.overlaps(marker.labelDimensions)
    )
  }
}
