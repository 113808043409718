import L from 'leaflet'
import _ from 'underscore'
import Map from 'lib/maps/map.js.erb'
import { PruneCluster, PruneClusterForLeaflet } from 'exports-loader?PruneCluster,PruneClusterForLeaflet!prunecluster/dist/PruneCluster.js'

export default class extends Map {
  constructor (container, options = {}) {
    super(container, options)

    // Set up prune cluster:
    this.pruneMarkers = {}
    this.markersGroup = L.featureGroup()
    this.pruneCluster = new PruneClusterForLeaflet()
    this.pruneCluster.PrepareLeafletMarker = function(leafletMarker, data) {
      let marker = data.marker
      marker.prepareClusterMarker(leafletMarker, false)
      leafletMarker.on('click', (event) => this.clusterMarkerClicked(marker))
    }.bind(this)

    this.leaflet.addLayer(this.pruneCluster)
    this.leaflet.addLayer(this.markersGroup)

    window.map = this
  }

  processView () {
    super.processView()
    this.pruneCluster.ProcessView()
  }

  addMarkers () {
    this.pruneCluster.RemoveMarkers()

    this.markers.forEach((marker) => {
      let pruneMarker = this.getPruneMarker(marker)
      this.pruneCluster.RegisterMarker(pruneMarker)
    })

    this.processView()
  }

  clearMarkers () {
    if (this.markers) {
      this.pruneCluster.RemoveMarkers()
    }

    this.processView()
  }

  getPruneMarker (marker) {
    if (!marker.resultId) marker.resultId = _.uniqueId('marker')

    return (
      this.pruneMarkers[marker.resultId] ||
      (this.pruneMarkers[marker.resultId] = new PruneCluster.Marker(marker.latLng.lat, marker.latLng.lng, { marker: marker }))
    )
  }

  clusterMarkerClicked (marker) {
    /*
     * Instead of opening the popup on the leaflet marker PruneCluster uses, we
     * activate the marker and open the popup on the leafletMarker of the venue.
     * This prevents the popup from disappearing when the marker is activated by
     * other means.
     */
    this.activateMarker(marker)

    this.pruneCluster.spiderfier.Unspiderfy()
    this.processView()

    if (marker.popup) {
      marker.openPopup()
    }
  }

  activateMarker (marker) {
    this.markersGroup.addLayer(marker.leaflet)
    this.getPruneMarker(marker).filtered = true

    super.activateMarker(marker)
  }

  deactivateMarker (marker) {
    super.deactivateMarker(marker)

    this.markersGroup.removeLayer(marker.leaflet)
    this.getPruneMarker(marker).filtered = false
  }
}
