import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['category']

  get categories () {
    return this.findControllers('menu-editor--category')
  }

  connect () {
    this.resetCategories = this.resetCategories.bind(this)
    this.resetCategories()
  }

  resetCategories () {
    // remove categories:
    this.categoryTargets.forEach((element) => {
      element.parentNode.removeChild(element)
    })

    this.categories.reverse().forEach((category) => {
      if (!category.destroyed && !category.swapping) {
        this.addCategoryLink(category)
      }
    })
  }

  addCategoryLink (category) {
    this.element.innerHTML = `
      <li data-${this.identifier}-target="category">
        <a href="#${category.anchor}" data-turbolinks="false">${category.title}</a>
      </li>
      ${this.element.innerHTML}
    `
  }

  newCategory (event) {
    event.preventDefault()

    let link   = event.currentTarget
    let anchor = link.href.split('#')[1]
    let target = document.getElementById(anchor)

    target.querySelector('a').click()
  }
}
