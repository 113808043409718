import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['result']

  connect () {
    this.timeline = {
      keydown: {},
      keyup:   {}
    }
  }

  get timestamp () {
    return new Date().getTime()
  }

  keydown (event) {
    this.timeline.keydown[this.timestamp] = event.charCode || event.keyCode
  }

  keyup (event) {
    this.timeline.keyup[this.timestamp] = event.charCode || event.keyCode
  }

  save () {
    if (this.hasResultTarget) {
      this.resultTarget.value = JSON.stringify(this.timeline)
    }
  }
}
