import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [ 'left', 'right', 'filter', 'venues', 'map', 'group', 'toggle', 'tag' ]

  connect () {
    this.pageXOffset  = null
    this.pageYOffset  = null
    this.filterActive = false
  }

  hideRight () {
    if (this.hasRightTarget) {
      this.rightTarget.classList.add('is-hidden')
    }
  }

  showRight () {
    if (this.hasRightTarget) {
      this.rightTarget.classList.add('is-hidden')
      this.rightTarget.classList.remove('is-hidden')
    }
  }

  applyFilter (event) {
    this.leftTarget.classList.toggle('is-loading')
  }

  expandFilter (event) {
    event.preventDefault()

    this.hideRight()

    this.element.classList.add('filters-toggled')
    this.filterTarget.classList.add('is-active')
  }

  collapseFilter (event) {
    event.preventDefault()

    this.showRight()

    this.element.classList.remove('filters-toggled')
    this.filterTarget.classList.remove('is-active')
  }

  checkFilterState (event) {
    if (this.element.classList.contains('filters-toggled') && this.rightTarget) {
      this.expandFilter(event)
    } else if (this.rightTarget) {
      this.collapseFilter(event)
    }
  }
}
