`import $ from 'jquery';`

addFormFields = (link, association, content, options = {}) ->
  newID  = new Date().getTime()
  regexp = new RegExp("new_#{association}", 'g')
  link   = $ link
  html   = $ content.replace(regexp, newID)
  delay  = 250
  delay  = parseInt(link.attr('data-delay')) if link.is('[data-delay]')

  if options.insertBefore?
    result = html.insertBefore link.closest(options.insertBefore)
  else
    ol = link.parents('fieldset').slice(0,1).find('ol')
    result = ol.append html

  result.hide().animate
    height:  'toggle'
    opacity: 'toggle'
  , delay, =>
    return if window.doNotFocus

    if options.focusOn?
      result.find(options.focusOn).focus()
    else
      $(result.find('input')[0])?.focus()

  $(document).trigger 'add-form-fields'

window.add_fields = addFormFields