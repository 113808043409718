import _ from 'underscore'
import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [ 'radioButton', 'pane' ]

  connect () {
    this.hideAllPanes()
    this.showSelectedPane()
  }

  change (event) {
    this.hideAllPanes()
    this.showSelectedPane()
  }

  hideAllPanes () {
    this.paneTargets.forEach((pane) => {
      pane.style.display = 'none'
    })
  }

  showSelectedPane () {
    this.radioButtonTargets.forEach((button) => {
      if (button.checked) {
        let value = button.value
        let pane  = this.findPaneByName(value)

        if (pane) {
          pane.style.display = 'block'
        }
      }
    })
  }

  findPaneByName (name) {
    return this.paneTargets.find((el) => el.dataset.radioPane == name)
  }
}
