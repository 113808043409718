import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [ 'day', 'title' ]

  // ATTRIBUTES:

  get today ()    { return new Date() }
  get tomorrow () { return new Date(this.today.getTime() + 24 * 60 * 60 * 1000) }

  get hasOpeningHours   () { return this.dayTargets.length > 0 }
  get closedPermanently () { return document.querySelectorAll('.venue-hours-closed-permanently').length >= 1 }

  get isOpenToday (){
    return !this.containsDate(this.closedDates, this.today) && (
      this.upcomingMeals.length ||
      this.containsDate(this.openedDates, this.today)
    )
  }

  get upcomingMeals () {
    let meals = this.mealsOnDate(this.today)
    return meals.filter((meal) => this.mealEndsInFuture(meal, this.today))
  }

  get openedDates () {
    return this.parseDateRanges(this.data.get('openedDates'))
  }

  get closedDates () {
    return this.parseDateRanges(this.data.get('closedDates'))
  }

  get visibleDays () {
    return this.dayTargets.filter(
      (day) => day.dataset.dayNumber == this.today.getDay() ||
               day.dataset.dayNumber == this.tomorrow.getDay()
    )
  }

  // ACTIONS:

  connect () {
    this.showOnlyVisibleDays()
    this.setTitle()
  }

  showAll (event) {
    event.preventDefault()

    let link = event.currentTarget
    link.parentNode.removeChild(link)

    this.showAllDays()
  }

  showOnlyVisibleDays () {
    this.dayTargets.forEach(
      (day) => day.classList.remove('is-visible')
    )

    this.visibleDays.forEach(
      (day) => day.classList.add('is-visible')
    )
  }

  showAllDays () {
    this.dayTargets.forEach(
      (day) => day.classList.add('is-visible')
    )
  }

  // TITLE GENERATION:

  setClosedTitle (text) {
    this.titleTarget.innerHTML = text
    this.titleTarget.classList.add('closed')
    this.titleTarget.classList.remove('open')
  }

  setOpenedTitle (text) {
    this.titleTarget.innerHTML = text
    this.titleTarget.classList.remove('closed')
    this.titleTarget.classList.add('open')
  }

  setTitle () {
    if (!this.hasTitleTarget || !this.hasOpeningHours) return false

    if (this.closedPermanently) {
      this.setClosedTitle(
        I18n.t('venues.opening_hours.closed_permanently')
      )
    } else if (this.isOpenToday) {
      let title = I18n.t('venues.opening_hours.open_today')

      if (this.upcomingMeals.length) {
        let meal = this.upcomingMeals[0]
        let options = {
          from:  this.mealStartsInFuture(meal, this.today) && meal.from && this.formatTime(meal.from),
          till:  meal.till && this.formatTime(meal.till),
          scope: 'venues.opening_hours'
        }

        if (options.from && options.till) {
          title = I18n.t('open_today_from_till', options)
        } else if (options.from) {
          title = I18n.t('open_today_from', options)
        } else if (options.till) {
          title = I18n.t('open_today_till', options)
        } else {
          title = I18n.t('open_today', options)
        }
      }

      this.setOpenedTitle(title)
    } else {
      this.setClosedTitle(
        I18n.t('venues.opening_hours.closed_today')
      )
    }
  }

  // HELPERS:

  containsDate (dates, date) {
    return dates.filter(
      (other) => date.toDateString() == other.toDateString()
    ).length > 0
  }

  daysBetween (dates) {
    let start = dates[0]
    let end   = dates[1]

    if (!end) {
      return dates
    } else {
      let current = start
      let days    = [current]
      while (current < end) {
        current = new Date(current.getTime() + 24 * 60 * 60 * 1000)
        days.push(current)
      }

      return days
    }
  }

  parseDateRange (range) {
    return this.daysBetween(
      range.split('…').map(
        (date) => new Date(Date.parse(date.trim()))
      )
    )
  }

  parseDateRanges (input) {
    if (!input || input.length == 0) return []

    let ranges = input.split(',').map(
      (range) => this.parseDateRange(range)
    )

    return ranges.reduce(
      (a, b) => { return a.concat(b) },
      []
    )
  }

  parseTime (input, onDate = this.today) {
    let date = new Date(onDate.getTime())
    let time = input.split(':').map((part) => parseInt(part))

    date.setUTCHours(time[0])
    date.setUTCMinutes(time[1])
    date.setUTCSeconds(0)

    return date
  }

  formatTime (time) {
    let hours   = `00${time.getHours()}`.slice(-2)
    let minutes = `00${time.getMinutes()}`.slice(-2)

    return `${hours}:${minutes}`
  }

  mealsOverlap (firstMeal, secondMeal) {
    return (
      (firstMeal.till  === null)          ||
      (secondMeal.from === null)          ||
      (firstMeal.till >= secondMeal.from)
    )
  }

  mealFromCell (cell, date) {
    let from = cell.dataset.from && this.parseTime(cell.dataset.from, date)
    let till = cell.dataset.till && this.parseTime(cell.dataset.till, date)

    if (till && from && till < from)
      till.setDate(till.getDate() + 1)

    return {
      from: from,
      till: till
    }
  }

  mealsFromRow (row, date) {
    let meals = Array.from(
      row.querySelectorAll('td')
    ).slice(1).map(
      (cell) => this.mealFromCell(cell, date)
    ).filter(
      (meal) => (meal.from != null) || (meal.till != null)
    )

    return meals.reduce((meals, meal) => {
      let lastMeal = meals[meals.length - 1];

      // Check if the meals overlap. If they do, extend the last meal in order
      // to make one consecutive meal
      if (lastMeal && this.mealsOverlap(lastMeal, meal)) {
        meals[meals.length - 1] = {
          from: lastMeal.from,
          till: meal.till
        }
      } else {
        meals.push(meal)
      }

      return meals
    }, [])
  }

  mealsOnDate (date) {
    let rows = this.dayTargets.filter(
      (day) => day.dataset.dayNumber == date.getDay()
    )

    if (rows.length) return this.mealsFromRow(rows[0], date)
    else             return []
  }

  mealEndsInFuture (meal, date) {
    return (meal.till == null || meal.till == '') || meal.till >= date
  }

  mealStartsInFuture (meal, date) {
    return meal.from && meal.from >= date
  }
}
