import L from 'leaflet'
import Marker from 'lib/maps/marker'

export default class extends Marker {
  get defaultOptions () {
    return {
      interactive: false,
      riseOnHover: true
    }
  }
  
  constructor (latLng, options = {}) {
    options.icon = L.divIcon({
      className:  'marker venue active',
      html:       '<div class="marker-wrapper"></div>',
      iconSize:   [13, 13],
      iconAnchor: [8, 22]
    })
    
    super(latLng, options)
  }
}
