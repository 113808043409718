`import $ from 'jquery';`
`import _ from 'underscore';`
`import URI from 'urijs';`
`import Bloodhound from 'corejs-typeahead';`
`require('corejs-typeahead/dist/typeahead.bundle.js');`

LIST_MEMBERSHIPS    = '.formtastic.list .list-memberships'
PRESENT_MEMBERSHIPS = '.formtastic.list .list-memberships .list-membership:not(.deleted)'
DELETED_MEMBERSHIPS = '.formtastic.list .list-memberships .list-membership.deleted'
LIST_MEMBERSHIP     = '.formtastic.list .list-memberships .list-membership'

LIST_ACTIONS        = '.formtastic.list .list-actions'
UNDO_DELETED_VENUES = '.formtastic.list .undo-deleted-venues'
UNDO_LINK           = '.formtastic.list .undo-deleted-venues .undo'
EMPTY               = '.formtastic.list .list-memberships .empty'
VENUE_ID            = '.formtastic.list input.venue-id'

DELETE_FROM_LIST = '.delete-from-list input[type=checkbox]'

TYPEAHEAD           = '.formtastic.list .typeahead'
TYPEAHEAD_CONTAINER = '.formtastic.list .twitter-typeahead'

LIST_MEMBERSHIP_LOADING = '<li class="list-membership-loading" />'

blankSlate = -> """
    <p class="empty">
      #{I18n.t('profiles.list_memberships.list_memberships_edit.empty')}
    </p>
  """

undoDeletedVenues = (count) -> """
    <div class="undo-deleted-venues">
      <span>
        #{I18n.t('profiles.lists.actions.venues_deleted', count: count)}
      </span>

      <a href="#" class="undo">
        #{I18n.t('profiles.lists.actions.undo')}
      </a>
    </div>
  """

venueNotFound = -> """
    <div class="tt-suggestion tt-help">
      #{I18n.t('profiles.lists.venue_autocomplete.no_venues_found')}
    </div>
  """

suggestion = (result) -> """
    <div class="tt-suggestion tt-selectable">
      <span class="tt-suggestion-title">#{result.name}</span>
      <span class="tt-suggestion-address">
        #{result.street}, #{result.zipcode}, #{result.city}
      </span>
    </div>
  """

updateUndoInfo = ->
  # Deferring the insertion of the element prevents a bug in Safari where only
  # part of the element is painted. This bug disappears when flex-grow is not
  # used for the element.
  _.defer ->
    count = $(DELETED_MEMBERSHIPS).length

    $(UNDO_DELETED_VENUES).remove()
    $(LIST_ACTIONS).prepend undoDeletedVenues(count) if count > 0

updateBlankSlate = ->
  if $(PRESENT_MEMBERSHIPS).length > 0
    $(EMPTY).remove()
  else
    $(LIST_MEMBERSHIPS).append $(blankSlate())

updateListMembershipDeleted = (membership) ->
  checkbox = membership.find(DELETE_FROM_LIST)

  if membership.is('.persisted')
    membership.toggleClass 'deleted', checkbox.prop('checked')
  else
    membership.remove() if checkbox.prop('checked')

isVenueInList = (id) ->
  $(VENUE_ID).is("[value=#{id}]")

$(document).on 'change', DELETE_FROM_LIST, (event) ->
  membership = $(event.currentTarget).closest(LIST_MEMBERSHIP)

  updateListMembershipDeleted(membership)
  updateUndoInfo()
  updateBlankSlate()

$(document).on 'click', UNDO_LINK, (event) ->
  event.preventDefault()

  $(DELETED_MEMBERSHIPS).each ->
    membership = $(@)

    membership.find(DELETE_FROM_LIST).prop 'checked', false
    updateListMembershipDeleted membership

  updateUndoInfo()
  updateBlankSlate()

$(document).on 'turbolinks:load', ->
  $(TYPEAHEAD).each ->
    input = $(this)
    url   = input.data('autocomplete')

    engine =
      new Bloodhound
        datumTokenizer: (datum) -> Bloodhound.tokenizers.whitespace(datum.value)
        queryTokenizer: Bloodhound.tokenizers.whitespace
        prefetch:       "#{url}?locale=#{I18n.currentLocale()}"
        remote:
          wildcard: '%QUERY'
          url:      "#{url}?query=%QUERY&locale=#{I18n.currentLocale()}"
          filter:   _.property('results')

    engine.initialize()

    options =
      hint:      true
      highlight: false
      minLength: 1

    dataset =
      source: engine.ttAdapter()
      limit: 7
      templates:
        notFound:   venueNotFound
        suggestion: suggestion

    input.typeahead options, dataset

$(document).on 'turbolinks:before-cache', ->
  $(TYPEAHEAD).typeahead 'destroy'

$(document).on 'typeahead:selected', TYPEAHEAD, (event, result) ->
  typeahead = $(event.currentTarget)
  typeahead.typeahead 'val', ''

  loading     = $(LIST_MEMBERSHIP_LOADING)
  memberships = $(LIST_MEMBERSHIPS)

  memberships.prepend(loading)

  membershipUrl = URI(typeahead.data('new-list-membership'))
  membershipUrl.setQuery('list_membership[venue_id]', result.id)

  $.ajax membershipUrl.toString(),
    success: (html) ->
      $(LIST_MEMBERSHIPS).prepend(html) unless isVenueInList(result.id)
      updateBlankSlate()
    complete: ->
      loading.remove()

$(document).on 'typeahead:asyncrequest', TYPEAHEAD, (event) ->
  $(event.currentTarget).closest(TYPEAHEAD_CONTAINER).addClass 'loading'

$(document).on 'typeahead:asynccancel typeahead:asyncreceive', TYPEAHEAD, (event) ->
  $(event.currentTarget).closest(TYPEAHEAD_CONTAINER).removeClass 'loading'
