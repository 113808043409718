import $ from 'jquery'

$(document).on('mouseenter', '.button.button-beautify', function() {
  $('.review-details .review').find('.body').hide()
  $('.review-details .review').find('.preview').show()
});

$(document).on('mouseleave', '.button.button-beautify', function() {
  $('.review-details .review').find('.preview').hide()
  $('.review-details .review').find('.body').show()
});

