export default class {
  get right  ()  { return this.left + this.width  }
  set right  (x) { this.width = x - this.left     }
  
  get bottom ()  { return this.top  + this.height }
  set bottom (y) { this.height = y - this.top     }
  
  get center ()  {
    return [
      this.left + (this.width  / 2),
      this.top  + (this.height / 2)
    ]
  }
  
  constructor (left, top, width, height) {
    this.left   = left
    this.top    = top
    this.width  = width
    this.height = height
  }
  
  overlaps (other) {
    return !(
      (this.right  < other.left)  ||
      (this.left   > other.right) ||
      (this.bottom < other.top)   ||
      (this.top    > other.bottom)
    )
  }
  
  padded (padding) {
    let clone = this.clone()
    clone.pad(padding)
    return clone
  }
  
  pad (padding) {
    this.left   -= padding
    this.top    -= padding
    this.width  += padding * 2
    this.height += padding * 2
  }
  
  clone () {
    return new this.constructor(this.left, this.top, this.width, this.height)
  }
}