import ApplicationController from './application_controller'
import _ from 'underscore'

export default class extends ApplicationController {

  static targets = ['newOrder']

  get pollingURL () {
    let url = this.data.get('pollingUrl')
    if (url && url.length) {
      return url
    } else {
      return false
    }
  }

  get reminderSoundURL () {
    let url = this.data.get('reminderSoundUrl')
    if (url && url.length) {
      return url
    } else {
      return false
    }
  }

  get reminderSoundDelay() {
    // set data-menu-order-index-reminder-sound-delay="5" or use default of 10 seconds
    return parseInt(this.data.get('reminderSoundDelay') || 10) * 1000
  }

  get notificationsController () {
    return this.findController('notifications')
  }

  connect() {
    _.defer(this.startPolling.bind(this))

    if (this.reminderSoundURL) {
      // Safari wants some interaction before autoplaying sound:
      this.remindSound = new Audio(this.reminderSoundURL)
      document.body.addEventListener('touchstart', () => this.unlockAudio())
      document.body.addEventListener('click',      () => this.unlockAudio())

      this.remindSound.addEventListener('canplaythrough', () => this.remind())
    }
  }

  disconnect() {
    this.stopPolling()
    this.remindSound = null
    clearTimeout(this.soundPoller)
  }

  startPolling () {
    if (this.pollingURL && this.notificationsController) {
      this.notificationsController.subscribe(this.pollingURL)
    }
  }

  stopPolling () {
    if (this.pollingURL && this.notificationsController) {
      this.notificationsController.unsubscribe(this.pollingURL)
    }
  }

  remind () {
    this.soundPoller = setTimeout(() => { this.remindAudiblyOfNewOrders() }, this.reminderSoundDelay)
  }

  unlockAudio () {
    if (!this.unlockedAudio) {
      this.remindSound.play()
      this.remindSound.pause()
      this.remindSound.currentTime = 0
      this.unlockedAudio = true
    }
  }

  remindAudiblyOfNewOrders () {
    if (this.newOrderTargets.length >= 1 && this.remindSound) {
      this.remindSound.play()
    }

    this.remind()
  }
}
