`import $ from 'jquery';`
`import _ from 'underscore';`
`import Bloodhound from 'corejs-typeahead';`
`require('corejs-typeahead/dist/typeahead.bundle.js');`

FORM                = '.review-autocomplete-form'
TYPEAHEAD           = '.review-autocomplete-form .typeahead'
TYPEAHEAD_CONTAINER = '.review-autocomplete-form .twitter-typeahead'

VENUE_AUTOCOMPLETE = '.review-autocomplete-form .venue-autocomplete'
VENUE_INFO         = '.review-autocomplete-form .venue'
VENUE_MANUAL       = '.review-autocomplete-form .venue-manual'

VENUE_NOT_IN_LIST = '.review-autocomplete-form .tt-help .venue-not-found'
VENUE_EDIT        = '.review-autocomplete-form .venue .edit'
VENUE_ID_INPUT    = '.review-autocomplete-form #review_venue_id_input input'
VENUE_NAME_INPUT  = '.review-autocomplete-form #review_venue_name_input input'

suggestion = (result) -> """
    <div class="tt-suggestion tt-selectable">
      <span class="tt-suggestion-title">#{result.name}</span>
      <span class="tt-suggestion-address">
        #{result.street}, #{result.zipcode}, #{result.city}
      </span>
    </div>
  """

venueNotInList = -> """
    <div class="tt-suggestion tt-help">
      <a href="#" class="venue-not-found">
        #{I18n.t('reviews.form_autocomplete.venue_not_in_list')}
      </a>
    </div>
  """

$(document).on 'turbolinks:load', ->
  return unless $(FORM).length > 0

  hasVenue = $(VENUE_ID_INPUT).val() > 0

  $(VENUE_AUTOCOMPLETE).toggleClass 'is-hidden', hasVenue
  $(VENUE_INFO).toggleClass         'is-hidden', !hasVenue

  $(TYPEAHEAD).each ->
    input = $(this)
    url   = input.data('autocomplete')

    engine =
      new Bloodhound
        datumTokenizer: (datum) -> Bloodhound.tokenizers.whitespace(datum.value)
        queryTokenizer: Bloodhound.tokenizers.whitespace
        prefetch:       "#{url}?locale=#{I18n.currentLocale()}"
        remote:
          wildcard: '%QUERY'
          url:      "#{url}?query=%QUERY&locale=#{I18n.currentLocale()}"
          filter:   _.property('results')

    engine.initialize()

    options =
      hint:      true
      highlight: false
      minLength: 1

    dataset =
      source: engine.ttAdapter()
      displayKey: (result) -> "#{result.name}, #{result.city}"
      limit:  7
      templates:
        footer:     venueNotInList
        notFound:   venueNotInList
        suggestion: suggestion

    input.typeahead options, dataset

$(document).on 'turbolinks:before-cache', ->
  $(TYPEAHEAD).typeahead 'destroy'

$(document).on 'typeahead:selected', TYPEAHEAD, (event, result) ->
  venueAutocomplete = $(VENUE_AUTOCOMPLETE)
  venueInfo         = $(VENUE_INFO)

  address = "#{result.street}, #{result.zipcode} #{result.city}"

  venueInfo.find('.title').text   result.name
  venueInfo.find('.address').text address

  venueInfo.removeClass      'is-hidden'
  venueAutocomplete.addClass 'is-hidden'

  $(VENUE_ID_INPUT).val result.id
  $(event.currentTarget).closest(TYPEAHEAD).typeahead 'val', ''

$(document).on 'click', VENUE_EDIT, (event) ->
  event.preventDefault()

  $(VENUE_INFO).addClass            'is-hidden'
  $(VENUE_AUTOCOMPLETE).removeClass 'is-hidden'

  $(VENUE_ID_INPUT).val ''

$(document).on 'click', VENUE_NOT_IN_LIST, (event) ->
  event.preventDefault()

  $(VENUE_NAME_INPUT).val($("#{TYPEAHEAD}.tt-input").val())

  $(event.currentTarget).closest(TYPEAHEAD).typeahead 'close'

  $(VENUE_AUTOCOMPLETE).addClass 'is-hidden'
  $(VENUE_MANUAL).removeClass    'is-hidden'

$(document).on 'typeahead:asyncrequest', TYPEAHEAD, (event) ->
  $(event.currentTarget).closest(TYPEAHEAD_CONTAINER).addClass 'loading'

$(document).on 'typeahead:asynccancel typeahead:asyncreceive', TYPEAHEAD, (event) ->
  $(event.currentTarget).closest(TYPEAHEAD_CONTAINER).removeClass 'loading'
