import _ from 'underscore'
import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  get fromID()       { return this.data.get('from') }
  get fromElement () { return document.getElementById(this.fromID) }

  get placeholder ()  { return this.element.placeholder }
  set placeholder (v) { this.element.placeholder = v    }

  get currentValue () { return this.fromElement && this.fromElement.value }

  connect () {
    this.update = this.update.bind(this)

    _.defer(() => {
      if (this.fromElement) {
        this.fromElement.addEventListener('keyup', this.update)
        this.update()
      }
    })
  }

  disconnect () {
    if (this.fromElement) {
      this.fromElement.removeEventListener('keyup', this.update)
    }
  }

  update () {
    this.placeholder = this.currentValue
  }
}
