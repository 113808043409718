import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = ['mail', 'checkbox']

  toggle() {
    this.mailTarget.parentNode.classList.toggle('is-hidden');
  }
}
